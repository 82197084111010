import React, { useState, useRef, useEffect, useContext } from 'react'
import person from "../assets/ic_user.svg"
import CircularProgress from '@mui/material/CircularProgress';
import InputTextField from './InputTextField';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import IsClientRunning, { checkPlatform } from '../util/IsClientRunning'
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import timeout from "../assets/ic_timeout.svg"
import Footer from './Footer';
import "../styles/loginBox.css"
import { browserFingerPrint, fetchPublicIP, getJWKToken, getUserAgentStatusAction, osPlatform, removeCookie } from '../util/action';
import { OauthUrl } from '../util/api';
import LogoFallback from './LogoFallback';
import { AppContext } from '../App';
const LoginUrl = process.env.REACT_APP_BASE_URL_LOGIN
const BaseUrl = process.env.REACT_APP_BASE_URL
// eslint-disable-next-line
const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/


let sessionExpired = false;
let firstTimeUser = true;


function LoginBox({ emailParam, SAMLRequest, RelayState, type, clientId }) {
    // After Clicking SignUp For Further Processing
    const [cookies, setCookie] = useCookies("");
    const [vis, setVis] = useState(false)
    const {setSendObj} = useContext(AppContext)
    // see if the cookies exist and show the SessionTimeout Screen accordingly
    let token = cookies.USER_AUTH?.Token
    try {
        firstTimeUser = cookies.FIRST?.isFirstTime
    }
    catch (_) { }
    if (!token && firstTimeUser === "false") {
        sessionExpired = true
    }

    const [isLoading, setIsLoading] = useState(false)
    const [errorColors, setErrorColors] = useState({ fieldset: 'rgba(0, 0, 0, 0.23)', labelNonFocus: 'rgba(0, 0, 0, 0.6)', labelFocus: '#003FB9' })
    const errorText = useRef('');
    const navigate = useNavigate()

    const [email, setEmail] = useState(emailParam || '')
    const handleSubmit = event => {
        event.preventDefault();
    }

    const getUserAgentStatus = async(eId) => {
        let obj = {
            osPlatform: osPlatform[navigator.platform] || navigator.platform,
            ipAddress: await fetchPublicIP(),
            user_email: email || eId,
            browserFingerPrint: browserFingerPrint
        }
        let data = await getUserAgentStatusAction(obj)
        
        if(data && data.status === 200){
            if(data.data?.access_mode === 'AGENT_LESS'){
                setSendObj({userMfa: data.data?.mfaType, passkeyData: data.data?.passkeyData, passkeyStatus: data.data?.passKeyStatus})
            }
           
            if(data.data?.device_status === "EXISTING"){
                let token = data.data.access_token;
                const payload = jwt_decode(token)
                const ExpDate = new Date(payload.exp * 1000);
                const ExpDate2 = new Date(payload.exp * 1000 + 30 * 60 * 1000);

                const sessionInterval = { Token: token };
                setCookie('USER_AUTH', sessionInterval, { expires: ExpDate });
                setCookie('USER_EMAIL', { email: email }, { expires: ExpDate });
                setCookie('ACCESS_MODE', "AGENT_LESS", { expires: ExpDate2 });
                if (emailParam && emailParam.match(regex) && RelayState && SAMLRequest) {
                    navigateToSSORedirect( emailParam, SAMLRequest, RelayState, token)
                    // clickHandler(navigateToSSORedirect, { emailParam, SAMLRequest, RelayState, token })
                }else if(RelayState && SAMLRequest && email){
                    navigateToSSORedirect( email, SAMLRequest, RelayState, token)
                }else if(data.data.mfa_status === 'UNVERIFIED'){
                    navigate('/setupMFA?step=1',{state: {userMfa: data.data?.mfaType, passkeyStatus: data.data?.passKeyStatus, passkeyData: data.data?.passkeyData}})
                }
                else {
                    if(data.data?.mfaType === 'PASSKEY' && data.data?.passKeyStatus === 'NOT_REGISTERED'){
                        navigate('/setupMFA2nd',{state: {userMfa: data.data?.mfaType, passkeyStatus: data.data?.passKeyStatus, passkeyData: data.data?.passkeyData}})
                    }else if(data.data?.mfaType === 'PASSKEY' && data.data?.passKeyStatus === 'REGISTERED'){
                        navigate('/verifyPasskey',{state: {userMfa: data.data?.mfaType, passkeyStatus: data.data?.passKeyStatus, passkeyData: data.data?.passkeyData}})
                    }else{
                        navigate('/setupMFA2nd',{state: {userMfa: data.data?.mfaType, passkeyStatus: data.data?.passKeyStatus, passkeyData: data.data?.passkeyData}})
                    }
                }
            }else if(data.data?.device_status === "NEW") {
                navigate(`/passcode`, {state: {email: email, userMfa: data.data?.mfaType, passkeyData: data.data?.passkeyData, passkeyStatus :data.data?.passKeyStatus}})
            }else {
                if (emailParam && emailParam.match(regex) && RelayState && SAMLRequest){
                    clickHandler(navigateToSSORedirect, { emailParam, SAMLRequest, RelayState, token })
                }else if(RelayState && SAMLRequest && email){
                    clickHandler(navigateToSSORedirect, { email, SAMLRequest, RelayState, token })
                }else {
                    clickHandler()
                }
            }
        }else if(data && data?.status === 409){
            setCookie('ACCESS_MODE', "AGENT_LESS", { expires: new Date(Date.now() + 30 * 60 * 1000) });
            return navigate('/accessBlock', { state: { ...data, email } })
        }else if(data && data?.status === 403){
            setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
            if(data.data && data.data?.message === 'LOCKED'){
            errorText.current.innerHTML = 'The user is locked. Please contact your admin for activating your account.'
            }else if(data.data && data.data?.message === 'INACTIVE'){
            errorText.current.innerHTML = 'The user is inactive. Please contact your admin for activating your account.'
            }else if(data.data && data.data?.message === 'YET_TO_ONBOARD'){
            errorText.current.innerHTML = 'The user is yet to onboard. Please click the activation link received on email and try again.'
            }else if(data.data && data.data?.message === 'ORG_INACTIVE'){
            errorText.current.innerHTML = 'The user subscription is inactive. Please contact your admin to reactivate your licenses.'
            }else {
            errorText.current.innerHTML = 'Something went wrong! Please try again later.'
            }
            setIsLoading(false)
        }else {
            if(data && data.data === 'USER_NOT_FOUND'){
                setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                errorText.current.innerHTML = 'The user does not exist. Please check and try again'
            }
            setIsLoading(false)
        }
    }
    const clickHandler = async (successCallback, callbackArguments) => {
        let token = cookies.USER_AUTH?.Token
        let isToken = false;
        // eslint-disable-next-line
        if (token && token !== '') {
            // eslint-disable-next-line
            isToken = true
        }

        axios.get(LoginUrl + 'zaperon/login/' + email).then((res) => {
            setIsLoading(false)
            if (res.status === 200 && res.data.statusmessage === 'success') {
                setSendObj({userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data, passkeyStatus: res.data?.passKey_status})
                const head = res.headers
                let token = head.get('Authorization');
                const payload = jwt_decode(token)
                const ExpDate = new Date(payload.exp * 1000);
                const sessionInterval = { Token: token };
                setCookie('USER_AUTH', sessionInterval, { expires: ExpDate });
                setCookie('USER_EMAIL', { email: email }, { expires: ExpDate });
                removeCookie('ACCESS_MODE')
                try {
                    const d = new Date();
                    setCookie('FIRST', { isFirstTime: "false" }, { expires: d });

                }
                catch (_) { }
                errorText.current.innerHTML = ""
                IsClientRunning(navigate, () => { }, token)
                if (res.data.verifyStatus === 'VERIFICATION_COMPLETE') {
                    // if (!SAMLRequest && !RelayState) {
                    //     if (isToken) {
                    //         navigate("/dashboard")
                    //     } else {
                    //         navigate("/setupMFA2nd");
                    //     }
                    // }
                    if (!SAMLRequest && !RelayState) {
                        if(type === 'oauth2'){
                            if(res.data?.mfa_type === 'PASSKEY'){
                                navigate(`/verifyPasskey?type=oauth2&clientId=${clientId}`, {state: {passkeyStatus: res.data?.passKey_status, userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data}})
                            }else if(res.data?.mfa_type === 'EMAIL'){
                                navigate(`/passcode?type=oauth2&clientId=${clientId}`, {state: {userMfa: res.data?.mfa_type, countVal: 90, verifyStatus: res.data?.verifyStatus}})
                            }else {
                                navigate(`/setupMFA2nd?type=oauth2&clientId=${clientId}`);
                            }
                        }else {
                            if(res.data?.mfa_type === 'PASSKEY' && res.data?.passKey_status === 'REGISTERED'){
                                navigate('/verifyPasskey', {state: {passkeyStatus: res.data?.passKey_status, userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data}})
                            }else if(res.data?.mfa_type === 'EMAIL'){
                                navigate('/passcode', {state: {userMfa: res.data?.mfa_type, countVal: 90, verifyStatus: res.data?.verifyStatus}})
                            }else {
                                navigate("/setupMFA2nd",{state: {passkeyStatus: res.data?.passKey_status, userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data}});
                            }
                        }
                    }
                }
                else if (res.data.verifyStatus === 'QR_COMPLETE') {
                    navigate("/setupMFA?step=3",{state: {passkeyStatus: res.data?.passKey_status, userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data}});
                }
                else if (res.data.verifyStatus === 'UNVERIFIED') {
                    if(res.data?.mfa_type === 'EMAIL'){
                        navigate('/passcode', {state: {userMfa: res.data?.mfa_type, countVal: 90, verifyStatus: res.data?.verifyStatus}})
                    }else {
                        navigate("/setupMFA?step=1", {state: {passkeyStatus: res.data?.passKey_status, userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data}});
                    }
                }
                else if (res.data.verifyStatus === 'LOCKED') {
                    console.log("You are LOCKED")
                }
                if(callbackArguments){
                    let args = (Object.values(callbackArguments))
                    if (successCallback) {
                        if(res.data?.mfa_type=== 'PASSKEY'){
                            navigate(`/verifyPasskey?username=${encodeURIComponent(args[0])}&SAMLRequest=${encodeURIComponent(args[1])}&RelayState=${encodeURIComponent(args[2])}`, {state:{userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data, passkeyStatus: res.data?.passKey_status} })
                        }else if(res.data?.mfa_type === 'EMAIL'){
                            navigate(`/passcode?username=${encodeURIComponent(args[0])}&SAMLRequest=${encodeURIComponent(args[1])}&RelayState=${encodeURIComponent(args[2])}`, {state: {userMfa: res.data?.mfa_type, countVal: 90, verifyStatus: res.data?.verifyStatus}})
                        }else {
                            successCallback(args[0], args[1], args[2], token, {userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data, passkeyStatus: res.data?.passKey_status})
                        }
                    }
                }
                
            }
            else if (res.status === 401) {
                setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                errorText.current.innerHTML = 'User does not exist. Please contact your admin.'
            }
            else if (res.status === 403) {
                setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                errorText.current.innerHTML = 'Sorry, you are not authorized to access this application.'
            }
            else {
                setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                errorText.current.innerHTML = 'Something went wrong! Please try again later.'
            }
        }).catch((e) => {
            console.log(e);
            if (e.message === 'Network Error') {
                setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                errorText.current.innerHTML = 'Sorry, we are unable to locate your identity. Please login to Zaperon Client App and try again.'
                setIsLoading(false)
            } else if (e.response?.status === 409) {
                if (SAMLRequest && RelayState) {

                    navigate(`/accessBlock?username=${encodeURIComponent(email)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`, { state: { ...e.response.data, email } });
                } else {
                    navigate('/accessBlock', { state: { ...e.response.data, email } })
                }
            } else {
                setIsLoading(false)
                setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                errorText.current.innerHTML = 'Something went wrong! Please try again later.'
            }
        })

    }



    const samlrequestHandler = async (email, saml, relay) => {
        let token = cookies.USER_AUTH?.Token
        if (!token) {
            // clickHandler(navigateToSSORedirect, { email, saml, relay, token })
            getUserAgentStatus()
        } else {
            navigateToSSORedirect(email, saml, relay, token)
        }
        // window.open(BaseUrl + 'ssologin?&username=' + email + '&SAMLRequest=' + saml + '&RelayState=' + relay, '_self')
    }
    const navigateToSSORedirect = async (email, saml, relay, token, sendObjData) => {
        axios.post(BaseUrl + 'ssologin?username=' + encodeURIComponent(email) + '&SAMLRequest=' + encodeURIComponent(saml) + '&RelayState=' + encodeURIComponent(relay), '_self', {
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            if (res.status === 200) {
                let url = res.data.acsUrl;
                let SAMLResponse = res.data.SAMLResponse;
                let RelayState = res.data.RelayState;
                // if (firstTimeUser != "false") {
                //     navigate(`/setupMFA2nd?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`)
                // } else {
                window.location.assign(`/Ssoserviceredirect?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`);
                // }
            }
            else if (res.status === 401 || res.status === 400) {
                if (res.data === 'UNVERIFIED') {
                    navigate("/login")
                }
                else if (res.data === 'QR_COMPLETE') {
                    navigate("/setupMFA?step=3")
                }
                else if (res.data === 'VERIFICATION_COMPLETE') {
                    navigate("/setupMFA")

                } else {
                    navigate("/login")
                }
                // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
            }
            else if (res.status === 403 || res.status === 405) {
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
        }).catch((error) => {
            // 401 status means user hasn't verified with the otp
            if (error.response.status === 403 || error.response.status === 405) {
                console.log("error")
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
            if (error.response.data.error === 'Unauthorized') {
                navigate("/sessionTimeout")
            }
            else if (error.response.data === 'UNVERIFIED') {
                navigate("/setupMFA?step=1")
            }
            else if (error.response.data === 'QR_COMPLETE') {
                navigate("/setupMFA?step=3")
            }
            else if (error.response.data === 'VERIFICATION_COMPLETE') {
                if(sendObjData?.userMfa === 'PASSKEY'){
                    console.log(sendObjData, ' send ojbject');
                    navigate(`/verifyPasskey?username=${encodeURIComponent(email)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`, {state: {...sendObjData}})
                }else {
                    navigate(`/setupMFA2nd?username=${encodeURIComponent(email)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`, {state: {...sendObjData}})
                }
            }
            else if (error.response?.status === 409) {
                navigate(`/accessBlock?username=${encodeURIComponent(email)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`, { state: { ...error.response.data, email } });
            } else {
                navigate("/login")
            }
            setIsLoading(false)
            setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
            errorText.current.innerHTML = 'Something went wrong! Please try again later.'
            // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
        })
    }
   
    // useEffect(() => {
    //     if (emailParam && emailParam.match(regex)) {
    //         clickHandler()
    //     }
    // }, [])

    // useEffect(() => {
    //     if (emailParam && RelayState && SAMLRequest) {
    //         samlrequestHandler(emailParam, SAMLRequest, RelayState);
    //     }
    // }, [])
    const getJWKTokenData = async (token) => {
        if(!cookies.USER_OAUTH?.Token){
        let data = await getJWKToken(token, clientId)
        if(data && data.status === 200){
            let jwk = jwt_decode(data.data?.access_token)
            setCookie('USER_OAUTH', {Token: data.data?.access_token}, {expires: new Date(jwk.exp * 1000)})
            window.location.href = `${OauthUrl}login?access_token=${data.data?.access_token}`;
            return data
        }else if(data && data.status === 403){
            return navigate('/unauthorised')
        }
    }else {
        window.location.href = `${OauthUrl}login?access_token=${cookies.USER_OAUTH?.Token}`;
    }
    }
    useEffect(() => {
        //call login api if the user is coming with only email
        // call login and ssologin api if the user has also relaystate and samlrequest with email
        let token = cookies.USER_AUTH?.Token
        if (!token) {
             if (emailParam && emailParam.match(regex) && RelayState && SAMLRequest) {
              getUserAgentStatus()
                // clickHandler(navigateToSSORedirect, { emailParam, SAMLRequest, RelayState, token })
            }else if(type === 'agent' && emailParam && emailParam.match(regex)){
                clickHandler()
            }
            else if (emailParam && emailParam.match(regex)) {
                // clickHandler()
                getUserAgentStatus()
            }
        }
        else {
            if(type === "oauth2"){
                getJWKTokenData(token)
            }else if(type === 'agent' && emailParam && emailParam.match(regex)){
                clickHandler()
            }
            else if (emailParam && emailParam.match(regex) && RelayState && SAMLRequest) {
                navigateToSSORedirect(emailParam, SAMLRequest, RelayState, token)
            }else {
                if(cookies.USER_EMAIL && cookies.ACCESS_MODE){
                    setEmail(cookies.USER_EMAIL?.email)
                    getUserAgentStatus(cookies.USER_EMAIL?.email)
                }
            }
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Box sx={{
                height: { sm: '85vh', xs: "75vh" }, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center"
            }}>
                <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '8.8vh', sm: '6.5vh', xs: "calc(15vh - 80px)" } }}><LogoFallback /></Box>
                <Box sx={{ marginBottom: '3%', background: `#EFEFEF`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: { sm: "40px", xs: "26px" } }}>


                    {/* <Box sx={{ width: { md: '50%', sm: '50%' }, height: { md: '50%', sm: '50%' }, }}> */}
                    <Box
                        component="img"
                        sx={{
                            height: { xs: 48, sm: 80 },
                            width: { xs: 48, sm: 80 },
                        }}
                        alt="person"
                        src={sessionExpired ? timeout : person}
                    />
                    {/* </Box> */}


                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '3vh' }}>
                    <Typography sx={{
                        font: { md: 'normal normal bold 32px/43px Open Sans', lg: 'normal normal bold 32px/43px Open Sans', xs: "normal normal bold 16px/20px Open Sans", sm: 'normal normal bold 32px/43px Open Sans' }, color: '#0B3558', marginBottom: '8px'
                    }}>
                        {sessionExpired ? "Your Session Has Expired!" : "Welcome!"}
                    </Typography>
                    <Typography sx={{
                        font: { md: 'normal normal normal 16px/22px Open Sans', lg: 'normal normal normal 16px/22px Open Sans', sm: 'normal normal normal 20px/27px Open Sans', xs: "normal normal normal 12px/16px Open Sans" }, color: '#0B3558', width: 'auto', textAlign: 'center'
                    }}>
                        {sessionExpired ? "Please enter your email again to continue" : "Let's connect to your workspace."}<br />
                        {sessionExpired ? "using your workspace." : "Please enter your credentials to continue."}
                    </Typography>
                </Box>
                <Box>
                    {/* Email Input Field */}
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <InputTextField
                                value={email || ""}
                                type='email'
                                onFocus={() => setVis(true)}
                                onBlur={() => setVis(false)}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    if (!e.target.value || e.target.value.match(regex)) {
                                        setErrorColors({ fieldset: 'rgba(0, 0, 0, 0.23)', labelNonFocus: 'rgba(0, 0, 0, 0.6)', labelFocus: '#003FB9' })
                                        errorText.current.innerHTML = ''
                                    }

                                }}
                                size='small'
                                InputProps={{
                                    sx: {
                                        font: { xs: 'normal normal 600 14px/15px Open Sans', sm: 'normal normal 600 14px/19px Open Sans' }, color: '#0B3558',
                                        ".css-1d3z3hw-MuiOutlinedInput-notchedOutline:active": {
                                            border: '2px solid #003FB9', borderRadius: '4px'
                                        },
                                        ".css-14lo706": {
                                            color: '#0B3558', width: '90px '
                                        },
                                        '.css-1x5jdmq': {
                                            padding: { sm: '10px 0 0 16px', xs: '10px 0 10px 16px' }
                                        },
                                        '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                            padding: { sm: '10px 0 0 16px', xs: '10px 0 10px 16px' }
                                        },
                                        height: { sm: "41px", xs: "40px" }
                                    },
                                }}

                                sx={{
                                    "& .fieldset.css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                        borderColor: errorColors.fieldset //feildset color
                                    },
                                    "& .label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                        color: errorColors.labelNonFocus, //email address label color,
                                        font: { xs: 'normal normal 600 12px/15px Open Sans', sm: 'normal normal 600 14px/19px Open Sans' },
                                        paddingRight: '4px',
                                        backgroundColor: 'white',
                                    },
                                    "& .label.Mui-focused": {
                                        color: errorColors.labelFocus //email address label color
                                    },
                                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                        color: '#A2A2A2', fontSize: { sm: '14px', xs: "12px" }, fontWeight: '300'
                                    },
                                    '& .css-1x5jdmq': {
                                        padding: { sm: '10px 0 0 16px', xs: '10px 0 14px 16px' }
                                    },
                                    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                        padding: { sm: '10px 0 0 16px', xs: '10px 0 14px 16px' }
                                    },

                                    margin: 0, width: { sm: "360px", xs: "300px" }, marginBottom: '4px', font: { xs: 'normal normal normal 12px/15px Open Sans', sm: 'normal normal normal 14px/19px Open Sans' },
                                    color: '#A2A2A2'
                                    , borderRadius: '4px', background: '#FFFFFF 0% 0% no-repeat padding-box', padding: '0'
                                }} label="Email Address" />
                            {/* Next Button */}
                            <p ref={errorText} style={{ font: 'normal normal 600 14px/22px Open Sans', margin: "0px 0px 0px 0px", color: '#D9292B', textAlign: 'center', padding: '0', width: "360px" }} className='errorPara'></p>
                            <Button
                                disabled={false}
                                type='submit'
                                variant="contained"
                                onClick={() => {
                                    if (email && email.match(regex)) {
                                        setIsLoading(true)
                                        setErrorColors({ fieldset: 'rgba(0, 0, 0, 0.23)', labelNonFocus: 'rgba(0, 0, 0, 0.6)', labelFocus: '#003FB9' })
                                        errorText.current.innerHTML = ''
                                        if (SAMLRequest && RelayState) {
                                            samlrequestHandler(email, SAMLRequest, RelayState)
                                        }
                                        else {
                                            // clickHandler(false)
                                            getUserAgentStatus()
                                        }
                                    }
                                    else {
                                        setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                                        errorText.current.innerHTML = 'Please enter a valid email.'
                                    }
                                }}
                                sx={{ marginTop: '1.85vh', textTransform: 'capitalize', background: '#003FB9 0% 0% no-repeat padding-box', boxShadow: '0px 3px 6px #00000029', borderRadius: '4px', height: { md: '43px', sm: '43px', xs: "43px" }, width: { sm: '360px', xs: "300px" }, marginLeft: "auto", marginRight: "auto", border: 'none' }}>
                                <Typography sx={{ width: 'fit-content', font: { md: 'normal normal 600 14px/19px Open Sans', sm: 'normal normal 600 14px/19px Open Sans' }, color: '#FFFFFF', fontWeight: 600 }}>
                                    {isLoading
                                        ?
                                        <CircularProgress style={{ width: '24px', height: '24px', color: '#FFFFFF' }} />
                                        // <lottiePlayer src={loadingImg} background="transparent" speed="1" style={{width:'100%',height:'100%'}} loop autoPlay></lottiePlayer>
                                        :
                                        sessionExpired ? `Continue` : `Next`
                                    }
                                </Typography>
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box >
            {(!vis || checkPlatform() === true) && <Footer />}
        </>
    )
}

export default LoginBox
