import React from 'react'
import poweredLogo from '../assets/zaperon_logo.png'
import './Footer.css'
function Footer() {
    return (
        <footer>
            <div className="flex foot-left ">
                <span style={{ marginRight: '12px', width: 'fit-content' }}>Powered by</span>
                <img className="foot-img" src={poweredLogo} alt=''/>
            </div>
            <div className="foot-right">
                {/* <a href='https://zaperon.com' style={{ marginRight: '48px' }}>Need Help?</a> */}
                <span style={{cursor: "pointer"}} onClick={() =>window.open('https://www.zaperon.com/privacyPolicy', '_blank')}>Privacy Policy</span> <span style={{ color: '#A2A2A2' }}>&amp;</span> <span style={{cursor: "pointer"}} onClick={() => window.open('https://www.zaperon.com/terms-conditions', '_blank')}>Terms</span>
            </div>
        </footer>
    )
}

export default Footer