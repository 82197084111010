import React from 'react';
import './LocationPopup.css';
import { browserName } from 'react-device-detect';

let locationGIFSource;

switch (browserName) {
    case "Firefox":
        locationGIFSource = "locationGifs/firefox_location_permission.gif"
        break;
    case "Chrome":
        locationGIFSource = "locationGifs/chrome_location_permission.gif"
        break;
    case "Edge":
        locationGIFSource = "locationGifs/ms_edge_location_permission.gif"
        break;
    default:
        locationGIFSource = "locationGifs/chrome_location_permission.gif"
}

function LocationPopup(props) {
    return (
        <>
            <div className="custom-model-main model-open">
                <div className="custom-model-inner">
                    <div className="custom-model-wrap">
                        <div className="pop-up-content-wrap">
                            <img src={locationGIFSource} alt="" className='location-video' />
                            <div className='pop-div'>
                                <h2 className='pop-head'>Allow Location Permission</h2>
                                <p className='pop-p'>We need location access to give you<br />better sign-in experience</p>
                                <button className='okbtn' onClick={props.clickHandler}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-overlay"></div>
            </div>
        </>
    );
}

export default LocationPopup

LocationPopup.propTypes = {
    // alert: PropTypes.bool
}
