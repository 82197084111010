import React, { useContext, useEffect, useRef, useState } from 'react'
import Footer from '../components/Footer'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import person from "../assets/ic_user.svg"
import { baseColor } from '../theme'
import { activateAccountWithEmailAction, browserFingerPrint, fetchPublicIP, getJWKToken, osPlatform, passcodeAction, resendAccessEmailAction, resendCodeWithEmailAction, verifyAccountWithEmailAction } from '../util/action'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import LogoFallback from '../components/LogoFallback'
import { AppContext } from '../App'
import LocationPopup from '../util/LocationPopup'
import { checkPlatform } from '../util/IsClientRunning'
import { BaseUrl, OauthUrl } from '../util/api'
import jwtDecode from 'jwt-decode'
import queryString from 'query-string'
import axios from 'axios'
const Passcode = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [passcode, setPasscode] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const [passcodeErr, setPasscodeErr] = useState(false)
    const location = useLocation()
    const [vis, setVis] =useState(false)
    const errorRef = useRef()
    const { url, SAMLResponse, RelayState, SAMLRequest, username, type, clientId } = queryString.parse(location.search);
    const {email, userMfa, passkeyData, passkeyStatus, countVal, verifyStatus} = location.state
    const [refresh, setRefresh] = useState(new Date())
    const {setSendObj} = useContext(AppContext)
// eslint-disable-next-line
    const [cookies, setCookie] = useCookies()
    const navigate = useNavigate()
    const [count, setCount] = useState(countVal || 180)
const [userLocation, setUserLocation] = useState({ lat: '', long: '', accuracy: "" })
// eslint-disable-next-line
let locationId;
const locationErrorCallback = (error) => {
    if (checkPlatform() === 'Mobile') {
        return setShowPopup(false)
    }
    if(error?.code === 1){
        setShowPopup(true)
    }
    console.log(error, error?.message);
};
const locationSuccessCallback = (position) => {
    if (!userLocation.accuracy || userLocation.accuracy === '' || userLocation.accuracy > position.coords.accuracy) {
        setUserLocation({ lat: position.coords.latitude, long: position.coords.longitude, accuracy: position.coords.accuracy })
    }
    setShowPopup(false)
};

    function formatTime(seconds) {
        if (seconds < 60) {
          return `${seconds} sec`;
        } else {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = seconds % 60;
          return `0${minutes}:${remainingSeconds.toString().padStart(2, '0')} min`;
        }
      }
    const sendPasscodeData = async(e) => {
        e.preventDefault()
        if(passcode && passcode.length === 6){
            if(countVal){
                let token = cookies.USER_AUTH?.Token
                let gpsEnabled = userLocation.accuracy < 100 ? "yes" : "no"
                var raw;
                if (userLocation.lat && userLocation.long && userLocation.lat !== "" && userLocation.long !== "") {

                    raw = {
                        "otp": passcode,
                        "location": userLocation.lat + "," + userLocation.long,
                        "gpsenabled": gpsEnabled,
                    };
                }else {
                    raw = {
                        "otp": passcode,
                        "location": "0,0",
                        "gpsenabled": "no",
                    }; 
                }

                    setPasscodeErr(false)
                    errorRef.current.innerHTML=''

                    if(verifyStatus === 'VERIFICATION_COMPLETE'){
                    setIsLoading(true)
                    let data = await verifyAccountWithEmailAction(token, raw, navigate);
                    setIsLoading(false)
                        if(data && data.status === 200){
                            if (locationId) {
                                navigator.geolocation.clearWatch(locationId);
                            }
                            if (SAMLRequest && RelayState && username) {
                                return navigateToSSORedirect(username, SAMLRequest, RelayState, cookies.USER_AUTH?.Token)
                            }else if (type === 'oauth2'){
                                return jwkTokenData(cookies.USER_AUTH?.Token)
                            }
                            else if (url && SAMLResponse && RelayState) {
                                return window.location.assign(`/Ssoserviceredirect?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`);
                            } else if (checkPlatform() === 'Mobile') {
                                return navigate('/dashboard')
                            }else {
                                let homeUrl = localStorage.getItem("zap_panel")
                                if (homeUrl && homeUrl !== 'dashboard') {
                                    window.location.href = `/${homeUrl}`
                                }
                                else {
                                    navigate('/dashboard')
                                }
                            }
                        }else if(data && data.status === 403 && data.data.message === 'INVALID_OTP'){
                            setPasscodeErr(true)
                            errorRef.current.innerHTML='Please enter a valid passcode.'
                        }else if(data && data.status === 403 && data.data.message === 'OTP_EXPIRED'){
                            setPasscodeErr(true)
                            errorRef.current.innerHTML='Your passcode has expired. Please try again.'
                        }else {
                              setPasscodeErr(true)
                            errorRef.current.innerHTML='Something went wrong. Please try again.'
                        }
                    }else {
                        setIsLoading(true)
                        let data = await activateAccountWithEmailAction(token, raw, navigate);
                        setIsLoading(false)

                        if(data && data.status === 200){
                            if (locationId) {
                                navigator.geolocation.clearWatch(locationId);
                            }
                            navigate('/dashboard')
                        }else if(data && data.status === 403 && data.data.message === 'INVALID_OTP'){
                            setPasscodeErr(true)
                            errorRef.current.innerHTML='Please enter a valid passcode.'
                        }else if(data && data.status === 403 && data.data.message === 'OTP_EXPIRED'){
                            setPasscodeErr(true)
                            errorRef.current.innerHTML='Your passcode has expired. Please try again.'
                        }else {
                            setPasscodeErr(true)
                          errorRef.current.innerHTML='Something went wrong. Please try again.'
                      }
                    }
            }else {

         setIsLoading(true)
        let obj = {
            user_email: email,
            passcode,
            os_version: osPlatform[navigator.platform] || navigator.platform,
            browserFingerPrint: browserFingerPrint,
            ip_address: await fetchPublicIP(),
         }
         setPasscodeErr(false)
         errorRef.current.innerHTML=''

        let data =await passcodeAction(obj)
        setIsLoading(false)

        if(data && data.status === 200) {
            let token = data.data.access_token
            const payload = jwt_decode(token)
            const ExpDate = new Date(payload.exp * 1000);
            const ExpDate2 = new Date(payload.exp * 1000 + 30 * 60 * 1000);
            const sessionInterval = { Token: token };
            setCookie('USER_AUTH', sessionInterval, { expires: ExpDate });
            setCookie('USER_EMAIL', { email: email }, { expires: ExpDate });
            setCookie('ACCESS_MODE', "AGENT_LESS", { expires: ExpDate2 });
            if(data.data.verify_status === 'UNVERIFIED'){
                if(userMfa === 'PASSKEY'){
                    setSendObj({userMfa: userMfa, passkeyData: data.data?.passkeyData, passkeyStatus: data.data?.passKeyStatus})
                    return navigate('/setupMFA?step=1', {state: {userMfa: userMfa, passkeyData: passkeyData, passkeyStatus: passkeyStatus}})
                }
                return navigate('/setupMFA?step=1', {state: {userMfa: userMfa}})
            }else {
                navigate("/setupMFA2nd",{state: {userMfa: userMfa, passkeyData: data.data?.passkeyData, passkeyStatus: data.data?.passKeyStatus}})
            }
        }else if(data && data?.status === 409){
            setCookie('ACCESS_MODE', "AGENT_LESS", { expires: new Date(Date.now() + 30 * 60 * 1000) });
            return navigate('/accessBlock', { state: { ...data, email } })
        }else {
            setPasscodeErr(true)
            errorRef.current.innerHTML='Please enter a valid passcode.'
        }
    }

    }

    }
    const jwkTokenData = async (token) => {
        if(!cookies.USER_OAUTH?.Token){
            let data = await getJWKToken(token, clientId)
            if(data && data.status === 200){
                let jwk = jwtDecode(data.data?.access_token)
                setCookie('USER_OAUTH', {Token: data.data?.access_token}, {expires: new Date(jwk.exp * 1000)})
                window.location.href = `${OauthUrl}login?access_token=${data.data?.access_token}`;
                return data
            }else if(data && data.status === 403){
                return navigate('/unauthorised', {state: {back: true}})
            }
        }else {
            window.location.href = `${OauthUrl}login?access_token=${cookies.USER_OAUTH?.Token}`;
        }
        
    } 
    const navigateToSSORedirect = async (email, saml, relay, token) => {
        axios.post(BaseUrl + 'ssologin?username=' + encodeURIComponent(email) + '&SAMLRequest=' + encodeURIComponent(saml) + '&RelayState=' + encodeURIComponent(relay), '_self', {
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            if (res.status === 200) {
                let url = res.data.acsUrl;
                let SAMLResponse = res.data.SAMLResponse;
                let relayState = res.data.RelayState;
                // if (firstTimeUser != "false") {
                //     navigate(`/setupMFA2nd?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`)
                // } else {
                if (checkPlatform() === false) {
                    setCookie('RELAY', relayState, { expires: new Date(Date.now() + 86400000) })
                    setCookie('SAML', SAMLResponse, { expires: new Date(Date.now() + 86400000) })
                    localStorage.setItem('saml', SAMLResponse)
                    localStorage.setItem('relay', relayState)
                }

                navigate(`/Ssoserviceredirect?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(relayState)}`)
                // }
            }
            else if (res.status === 401 || res.status === 400) {
                if (res.data === 'UNVERIFIED') {
                    navigate("/login")
                }
                else if (res.data === 'QR_COMPLETE') {
                    navigate("/setupMFA?step=3")
                }
                else if (res.data === 'VERIFICATION_COMPLETE') {
                    navigate("/setupMFA")

                }

                else {
                    navigate("/login")
                }


                // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
            }
            else if (res.status === 403 || res.status === 405) {
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
        }).catch((error) => {
            // 401 status means user hasn't verified with the otp
            if (error.response.status === 403 || error.response.status === 405) {
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
            else if (error.response.data.error === 'Unauthorized') {
                navigate("/sessionTimeout")
            }
            else if (error.response.data === 'UNVERIFIED') {
                navigate("/setupMFA?step=1")
            }
            else if (error.response.data === 'QR_COMPLETE') {
                navigate("/setupMFA?step=3")
            }
            else if (error.response.data === 'VERIFICATION_COMPLETE') {
                navigate(`/setupMFA2nd?username=${encodeURIComponent(username)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`)
            } else if (error.response?.status === 409) {
                navigate("/accessBlock", { state: { ...error.response.data, email } });
            } else {
                navigate("/login")
            }
            errorRef.current.innerHTML = "Something went wrong! Please contact your admin"

            // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
        })
    }
    const handleResend = async() => {
        if(count === 0){
            setCount(countVal || 180)
            setRefresh(new Date())
            if(countVal){
                let data = await resendCodeWithEmailAction(cookies?.USER_AUTH.Token)
                if(data && data.status === 200){
                    // setRefresh(new Date())
                }else {
                    setCount(0)
                }
            }else {
                let data = await resendAccessEmailAction(email)
                if(data && data.status === 200){
                    // setRefresh(new Date())
                }else {
                    setCount(0)
                }
            }  
        }
    }
    useEffect(() => {
        const timer = setInterval(() => {
            setCount(prev => {
                if (prev === 0) {
                    clearInterval(timer);
                    return 0;
                } else {
                    return prev - 1;
                }
            });
        }, 1000);
    
        return () => clearInterval(timer);
    
    }, [refresh]);
    const fetchLocation = () => {
        setShowPopup(false)
        locationId = navigator.geolocation.getCurrentPosition(locationSuccessCallback, locationErrorCallback);
    };
    useEffect(() => {
       
        // to get location
        if(countVal){
        let token = cookies.USER_AUTH?.Token
        if (!token) {
            navigate("/login")
        }
        if (checkPlatform() === true) {
            navigator.permissions && navigator.permissions.query({ name: 'geolocation' })
                .then(function (PermissionStatus) {
                    if (PermissionStatus.state === 'granted') {
                        //allowed
                        fetchLocation()
                    } else if (PermissionStatus.state === 'prompt') {
                        // prompt - not yet granted or denied
                        setShowPopup(true)
                    } else {
                        //denied
                        setShowPopup(true)
                    }
                })
        }
        else if (checkPlatform() === 'Mobile') {
            fetchLocation()
        }
    }
// eslint-disable-next-line
    },[countVal])
  return  <>
     {showPopup &&
        <LocationPopup clickHandler={fetchLocation} />}
  <Box sx={{
          height:{sm: '85vh',xs: "75vh" },  display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center"
      }}>
          <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '8.8vh', sm: '6.5vh', xs: "calc(15vh - 70px)" } }}><LogoFallback /></Box>
          <Box sx={{ marginBottom: '16px', background: `#EFEFEF`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: {sm: "40px", xs: "26px"} }}>


              {/* <Box sx={{ width: { md: '50%', sm: '50%' }, height: { md: '50%', sm: '50%' }, }}> */}
              <Box
              component="img"
              sx={{
                  height: { xs: 48, sm: 80 },
                  width: { xs: 48, sm: 80 },
              }}
              alt="person"
              src={person}
              />
              {/* </Box> */}


          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '3vh' }}>
              <Typography sx={{
                  font: { xs: "normal normal bold 16px/20px Open Sans", sm: 'normal normal bold 32px/43px Open Sans' }, color: '#0B3558', marginBottom: '8px'
              }}>
                  Enter Passcode
              </Typography>
              <Typography sx={{
                  font: { sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/16px Open Sans" }, color: '#0B3558', width: 'auto', textAlign: 'center'
              }}>
                  We have sent you a 6 digit passcode on your email. <br/>Please enter the code below.
              </Typography>
          </Box>
          <Box>
              {/* Email Input Field */}
              <form onSubmit={sendPasscodeData}>
              <Box>
                 <input value={passcode || ""} onChange={(e) => {
                    let {value} = e.target;
                    setPasscodeErr(false)
                    errorRef.current.innerHTML=''
                    if(value !== "" && (value.length > 6 || !(/^\d+$/.test(value)))){
                        return
                    }else {
                        setPasscode(value)
                    }
                 }} type='text' 
                 onFocus={() => setVis(true)}
                 onBlur={() => setVis(false)}
                 className='passcode-text' style={{height: "44px", letterSpacing: "10px", border: passcodeErr?'2px solid #D9292B': '2px solid #003FB9', borderRadius: '4px', textAlign: 'center', font: "normal normal bold 24px/33px Open Sans",color: "#0B3558"}} />
                <p ref={errorRef} style={{ font: 'normal normal 600 14px/22px Open Sans', margin: "0px 0px 0px 0px", color: '#D9292B', textAlign: 'center', padding: '0' }} className='errorPara passcode-text'></p>

              </Box>
                  <Button
                      disabled={passcode && passcode?.length === 6? false : true}
                      type='submit'
                      variant="contained"
                      sx={{ marginTop: '1.85vh', textTransform: 'capitalize', background: '#003FB9 0% 0% no-repeat padding-box', boxShadow: '0px 3px 6px #00000029', borderRadius: '4px', height: { md: '43px', sm: '43px', xs: "43px" }, width: {sm: '360px', xs: "300px"},marginLeft: "auto", marginRight: "auto", border: 'none' }}>
                      <Typography sx={{ width: 'fit-content', font: { sm: 'normal normal 600 14px/19px Open Sans' }, color: '#FFFFFF', fontWeight: 600 }}>
                          {isLoading
                              ?
                              <CircularProgress style={{ width: '32px', height: '32px', color: '#FFFFFF' }} />
                              :
                               `Verify`
                          }
                      </Typography>
                  </Button>
              </form>
              <p onClick={() => handleResend()} style={{color: count === 0 ? baseColor.primary: baseColor.textLight, cursor: "pointer", font: "normal normal 600 14px/19px Open Sans", marginTop: "16px", textAlign: "center" }}><span style={{textDecoration: "underline"}}>Resend New Passcode</span><span style={{ color: baseColor.primary}}>{count !== 0 && ` in ${formatTime(count)}`}</span></p>
          </Box>
      </Box >
      {(!vis || checkPlatform() === true) && <Footer />}
 </>
}

export default Passcode