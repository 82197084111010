import React, { useState } from 'react'
import zaplogo from "../assets/ic_zaperon_logo.svg"
import { orgSubdomain } from '../App';
const LogoFallback = React.memo(({width, height, style}) => {
    const imgUrl = `https://finlocks3.s3.ap-south-1.amazonaws.com/logo/${orgSubdomain}.png?timestamp=${Date.now()}`
    const [primaryImageLoaded, setPrimaryImageLoaded] = useState(true);

    const handlePrimaryImageError = () => {
      setPrimaryImageLoaded(false);
    };
  return (
    <>
        <img src={imgUrl}  onError={(e) => { e.target.style.display = 'none'; handlePrimaryImageError()}} alt='logo' style={{
                    width: width || 'inherit', height: height || "inherit", ...style
                }} />
        { !primaryImageLoaded && <img
        src={zaplogo}
        alt='zap'
        style={{
            width: width || 'inherit', height: height || 'inherit', ...style
        }}
      />}
    </>
  )
})

export default LogoFallback