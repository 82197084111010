import React from 'react'
import Footer from '../components/Footer'
import { Box, Button, Typography } from '@mui/material'
import ic_unauthorized_access from "../assets/ic_unauthorized_access.svg"
import { useLocation, useNavigate } from 'react-router-dom';
import LogoFallback from '../components/LogoFallback';
const responsive = {
    verifyAccount: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    verifyAccountText1: {
        font: { sm: 'normal normal bold 32px/43px Open Sans', xs: "normal normal bold 16px/25px Open Sans" },
        color: '#0B3558',
        marginBottom: '8px'
    },
    verifyAccountText2: {
        font: { sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/15px Open Sans" },
        letterSpacing: 0,
        color: '#0B3558',
        width: 'auto',
        textAlign: 'center',
        marginBottom: { md: '26px', sm: '2.5vh', xs: "3vh" },
    },
    totalMarginLeft: {
        display: 'flex', flexDirection: 'column', alignItems: 'center'
    },
}
const Unauthorised = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Box sx={{
            ...responsive.totalMarginLeft, height: { sm: "85vh", xs: "80vh" }
        }}>
            <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '12vh', sm: '10vh', xs: "10vh" }, marginTop: { xs: "5vh", sm: "7vh" } }}><LogoFallback /></Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ ...responsive.verifyAccount }}>

                    <Box sx={{ marginBottom: '3%', background: `#EFEFEF`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: { sm: "40px", xs: "26px", lg: "50px" } }}>
                        <Box
                            component="img"
                            sx={{
                                height: { xs: 48, sm: 80, lg: 100 },
                                width: { xs: 48, sm: 80, lg: 100 },
                            }}
                            alt="unauth_logo"
                            src={ic_unauthorized_access}
                        />
                    </Box>
                    <Typography sx={{ ...responsive.verifyAccountText1 }}>Unauthorized Access!</Typography>
                    <Typography sx={{ ...responsive.verifyAccountText2 }}>You are not authorized to access this page</Typography>
                </Box>
            </Box>
            <Button
                disabled={false}
                type='submit'
                variant="contained"
                onClick={() => {
                    if (location.state?.sso) {
                        navigate('/login');
                    }else if(location.state?.back){
                        window.history.go(-3)
                    }else if(location.state?.one){
                        window.history.go(-1)
                    }
                    else {
                        window.history.go(-2)
                    }
                }}
                sx={{ marginTop: '0vh', textTransform: 'capitalize', background: '#003FB9 0% 0% no-repeat padding-box', boxShadow: '0px 3px 6px #00000029', borderRadius: '4px', height: { sm: "43px", sx: "50px" }, width: { sm: '350px', xs: "300px" }, border: 'none' }}>
                <Typography sx={{ width: 'fit-content', font: { sm: 'normal normal 600 14px/19px Open Sans' }, color: '#FFFFFF', fontWeight: 600 }}>
                    Go Back
                </Typography>
            </Button>
            <Footer />
        </Box>
    )
}

export default Unauthorised