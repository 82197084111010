import React, { Suspense, createContext, lazy, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { createTheme, ThemeProvider } from "@mui/material";

// import Dashboard from "./pages/Dashboard";
import SetupMFA from "./pages/SetupMFA";
import Redirect from "./pages/Redirect";
import SetupMFA_2nd from "./pages/SetupMFA_2nd";
import SessionTimeout from "./pages/SessionTimeout"
import Welcome from "./pages/Welcome";
import Disconnected from "./pages/Disconnected";
import Ssoserviceredirect from "./pages/Ssoserviceredirect";
// import AccessBlock from './pages/AccessBlock';
import Unauthorised from './pages/Unauthorised';
import Passcode from './pages/Passcode';
import CreatePasskey from './pages/CreatePasskey';
import VerifyPasskey from './pages/VerifyPasskey';

const Dashboard = lazy(() => import("./pages/Dashboard"));
// const Redirect = lazy(() => import("./pages/Redirect"));
// const SetupMFA_2nd = lazy(() => import("./pages/SetupMFA_2nd"));
// const SessionTimeout = lazy(() => import("./pages/SessionTimeout"));
// const Welcome = lazy(() => import("./pages/Welcome"));
// const Disconnected = lazy(() => import("./pages/Disconnected"));
// const Ssoserviceredirect = lazy(() => import("./pages/Ssoserviceredirect"));
const AccessBlock = lazy(() => import("./pages/AccessBlock"));
// const Unauthorised = lazy(() => import("./pages/Unauthorised"));
// const SetupMFA = lazy(() => import("./pages/SetupMFA"));

const values = {
  xs: 0,
  sm: 500,
  lg: 1280,
  md: 1537,
  xl: 1920
};

const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "lg", "md", "xl"],
    up: (key) => `@media (min-width:${values[key]}px)`
  },
  palette: {
    primary: {
      main: '#003FB9'
    },
  },
  typography: {
    fontFamily: "Open Sans"
  },
});

export const AppContext = createContext()
export const orgSubdomain = window.location.href.split('//')[1].split('.')[0]
function App() {
  const subdomainCaps = orgSubdomain.charAt(0).toUpperCase() + orgSubdomain.slice(1);
  const [sendObj, setSendObj] = useState()
  useEffect(() => {
    const primaryFavicon = document.createElement('link');
    primaryFavicon.rel = 'shortcut icon';
    primaryFavicon.href = `https://finlocks3.s3.ap-south-1.amazonaws.com/favicon/${orgSubdomain}.ico?timestamp=${Date.now()}`;
    
    const fallbackFavicon = document.createElement('link');
    fallbackFavicon.rel = 'shortcut icon';
    fallbackFavicon.href = process.env.PUBLIC_URL + `/favicon.ico`;
  
    const img = document.createElement('img');
    img.src = primaryFavicon.href;
    img.onload = () => {
      document.head.appendChild(primaryFavicon);
      document.title = subdomainCaps
      img.remove();
    };
    img.onerror = () => {
      document.head.appendChild(fallbackFavicon);
      img.remove();
    };
  
    // Cleanup function
    return () => {
      // Remove both favicons when the component unmounts
      document.head.removeChild(primaryFavicon);
      document.head.removeChild(fallbackFavicon);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <AppContext.Provider value={{sendObj, setSendObj}} >
    <ThemeProvider theme={theme}>

      <CookiesProvider>
        <Suspense fallback={<Redirect />}>
          <Routes>
            <Route path="/accessBlock" element={<AccessBlock />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Suspense>
        <Routes>


          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login/" element={<Welcome />} />
          <Route path='/passcode' element={<Passcode />} />
          <Route path="/redirect" element={<Redirect />} />
          <Route path='/unauthorised' element={<Unauthorised />} />
          <Route path="/Ssoserviceredirect" element={<Ssoserviceredirect />} />
          <Route path="/disconnected" element={<Disconnected />} />
          <Route path="/setupMFA" element={<SetupMFA />} />
          <Route path="/createPasskey" element={<CreatePasskey />} />
          <Route path="/verifyPasskey" element={<VerifyPasskey />} />


          {/* eslint-disable-next-line */}
          <Route path="/setupMFA2nd" element={<SetupMFA_2nd />} />
          <Route path="/sessionTimeout" element={<SessionTimeout />} />
        </Routes>

      </CookiesProvider>
    </ThemeProvider >
    </AppContext.Provider>
  );
}

export default App;
