import { Box, Typography } from '@mui/material'
import React from 'react'
import tick from '../assets/ic_tick.svg'

function SetupMFAListItem({ styles, current, text, index, checked }) {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', ...styles.box }}>
            <Box sx={{ ...styles.icon, transition: '.5s all', backgroundColor: current ? '#003FB9' : checked ? '#4FD25C' : '', border: current ? '4px solid #003FB9' : checked ? '4px solid #4FD25C' : '4px solid #A2A2A2', display: 'flex', color: current ? "#FFFFFF" : checked ? '#FFFFFF' : '#A2A2A2', alignItems: 'center', justifyContent: 'center' }} >
                {checked ? <img src={tick} alt=''/> : index}
            </Box>
            <Typography sx={{ ...styles.text, display: 'inline', color: current ? '#003FB9' : checked ? '#A2A2A2' : '#0B3558' }}>{text}</Typography>
        </Box>
    )
}

export default SetupMFAListItem