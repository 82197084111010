import { Box } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import LoginBox from "../components/LoginBox";
import { useCookies } from 'react-cookie';

// eslint-disable-next-line
const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

function Welcome() {
    const [searchParams] = useSearchParams()
    const [cookies] = useCookies("");
    let email = searchParams.get("username")
    let type = searchParams.get("type")
    let clientId = searchParams.get("client_id")
    if(!email){
        email= searchParams.get('email')
    }
    if (!email?.match(regex)) {
        try {
            email = cookies.USER_EMAIL?.email
        }
        catch (_) { }
    }


    return <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
        <LoginBox emailParam={email} clientId={clientId} type={type} SAMLRequest={searchParams.get("SAMLRequest")} RelayState={searchParams.get("RelayState")} />
    </Box>;
}

export default Welcome;
