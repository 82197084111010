import React from 'react'
import Footer from '../components/Footer'
import disImg from "../assets/ic_zaperon_disconnected.png";
import { useNavigate } from 'react-router-dom';
import './SetupMFA.css';
import { Box, Typography } from '@mui/material'
import LogoFallback from '../components/LogoFallback';

function clickHandler(nav) {
    try {
        window.location.href = 'zap://'
    }
    catch (e) { }
}
function Disconnected() {
    const navigate = useNavigate();

    return (
        <>
            <div style={{ width: '100vw', justifyContent: 'center', display: 'flex', height: '90vh' }}>
                <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '8.8vh', sm: '6.5vh' }, marginTop: 'calc(15vh - 54px)', position: 'absolute' }}><LogoFallback /></Box>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>



                    <img src={disImg} alt="disconnected" style={{ width: '488px', height: '180px' }} />

                    <h1 style={{ font: { md: 'normal normal bold 48px/65px Open Sans', lg: 'normal normal bold 5.55vh/7.52vh Open Sans', sm: 'normal normal bold 32px/43px Open Sans' }, color: '#0B3558', marginBottom: '8px', marginTop: "1.48vh" }}>You are Disconnected!</h1>

                    <Typography sx={{
                        font: { md: 'normal normal normal 20px/27px Open Sans', lg: 'normal normal normal 2.3vh/3.1vh Open Sans', sm: 'normal normal normal 20px/27px Open Sans' }, color: '#0B3558', width: 'auto', textAlign: 'center', marginBottom: '2.22vh'
                    }}>
                        Log in to Zaperon to access your workspace
                    </Typography>
                    <button className='nxtBtn' onClick={() => clickHandler(navigate)}>Sign In</button>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Disconnected