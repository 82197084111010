import React, { useContext, useEffect, useRef, useState } from 'react'
import timeout from "../assets/ic_timeout.svg"
import InputTextField from '../components/InputTextField';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import IsClientRunning from '../util/IsClientRunning';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Footer from '../components/Footer';
import { browserFingerPrint, fetchPublicIP, getUserAgentStatusAction, osPlatform, removeCookie } from '../util/action';
import LogoFallback from '../components/LogoFallback';
import { AppContext } from '../App';
// eslint-disable-next-line
const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
function SessionTimeout() {
    const LoginUrl = process.env.REACT_APP_BASE_URL_LOGIN;
    const [email, setEmail] = React.useState()
    const [errorColors, setErrorColors] = useState({ fieldset: 'rgba(0, 0, 0, 0.23)', labelNonFocus: 'rgba(0, 0, 0, 0.6)', labelFocus: '#003FB9' })
    const errorText = useRef();
    const [isLoading, setIsLoading] = useState(false)
    const [cookies, setCookie] = useCookies()
    const navigate = useNavigate()
    const {setSendObj} = useContext(AppContext)
    const handleSubmit = event => {
        event.preventDefault();
    }
    const getUserAgentStatus = async(eId) => {
        let obj = {
            osPlatform: osPlatform[navigator.platform] || navigator.platform,
            ipAddress: await fetchPublicIP(),
            user_email: email || eId,
            browserFingerPrint: browserFingerPrint
        }
        // console.log(obj,' object')
        let data = await getUserAgentStatusAction(obj)
        if(data && data.status === 200){
            if(data.data?.access_mode === 'AGENT_LESS'){
                setSendObj({userMfa: data.data?.mfaType, passkeyData: data.data?.passkeyData, passkeyStatus: data.data?.passKeyStatus})
            }
            if(data.data?.device_status === "EXISTING"){
            let token = data.data.access_token;
            const payload = jwt_decode(token)
            const ExpDate = new Date(payload.exp * 1000);
            const ExpDate2 = new Date(payload.exp * 1000 + 30 * 60 * 1000);
            const sessionInterval = { Token: token };
            setCookie('USER_AUTH', sessionInterval, { expires: ExpDate });
            setCookie('USER_EMAIL', { email: email }, { expires: ExpDate });
            setCookie('ACCESS_MODE', "AGENT_LESS", { expires: ExpDate2 });
            if(data.data?.mfaType === 'PASSKEY' && data.data?.passKeyStatus === 'NOT_REGISTERED'){
                navigate('/setupMFA2nd',{state: {userMfa: data.data?.mfaType, passkeyStatus: data.data?.passKeyStatus, passkeyData: data.data?.passkeyData}})
            }else if(data.data?.mfaType === 'PASSKEY' && data.data?.passKeyStatus === 'REGISTERED'){
                navigate('/verifyPasskey',{state: {userMfa: data.data?.mfaType, passkeyStatus: data.data?.passKeyStatus, passkeyData: data.data?.passkeyData}})
            }else{
                navigate('/setupMFA2nd',{state: {userMfa: data.data?.mfaType, passkeyStatus: data.data?.passKeyStatus, passkeyData: data.data?.passkeyData}})
            }
        }else if(data.data?.device_status === "NEW") {
            navigate(`/passcode`, {state: {email: email, userMfa: data.data?.mfaType, passkeyData: data.data?.passkeyData, passkeyStatus :data.data?.passKeyStatus}})
        }else {
           clickHandler()
        }
        }else if(data && data?.status === 409){
            setCookie('ACCESS_MODE', "AGENT_LESS", { expires: new Date(Date.now() + 30 * 60 * 1000) });
            return navigate('/accessBlock', { state: { ...data, email } })
        }else if(data && data?.status === 403){
            setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
            if(data.data && data.data?.message === 'LOCKED'){
            errorText.current.innerHTML = 'The user is locked. Please contact your admin for activating your account.'
            }else if(data.data && data.data?.message === 'INACTIVE'){
            errorText.current.innerHTML = 'The user is inactive. Please contact your admin for activating your account.'
            }else if(data.data && data.data?.message === 'YET_TO_ONBOARD'){
            errorText.current.innerHTML = 'The user is yet to onboard. Please click the activation link received on email and try again.'
            }else if(data.data && data.data?.message === 'ORG_INACTIVE'){
            errorText.current.innerHTML = 'The user subscription is inactive. Please contact your admin to reactivate your licenses.'
            }else {
            errorText.current.innerHTML = 'Something went wrong! Please try again later.'
            }
            setIsLoading(false)
        }else {
            if(data && data.data === 'USER_NOT_FOUND'){
                setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                errorText.current.innerHTML = 'The user does not exist. Please check and try again'
            }
            setIsLoading(false)
        } }
    const clickHandler = async (successCallback, callbackArguments) => {
        if(email && email !== ""){
            let token = cookies.USER_AUTH?.Token
            let isToken = false;
            if (token && token !== '') {
                // eslint-disable-next-line
                isToken = true
            }
    
            axios.get(LoginUrl + 'zaperon/login/' + email).then((res) => {
                setIsLoading(false)
                if (res.status === 200 && res.data.statusmessage === 'success') {
                    const head = res.headers
                    let token = head.get('Authorization');
                    const payload = jwt_decode(token)
                    const ExpDate = new Date(payload.exp * 1000);
                    const sessionInterval = { Token: token };
                    setCookie('USER_AUTH', sessionInterval, { expires: ExpDate });
                    setCookie('USER_EMAIL', { email: email }, { expires: ExpDate });
                    removeCookie('ACCESS_MODE')
                    try {
                        const d = new Date();
                        setCookie('FIRST', { isFirstTime: "false" }, { expires: d });
                    }
                    catch (_) { }
                    IsClientRunning(navigate, () => { }, token)
                    if (res.data.verifyStatus === 'VERIFICATION_COMPLETE') {
                        // if (!SAMLRequest && !RelayState) {
                        //     if (isToken) {
                        //         navigate("/dashboard")
                        //     } else {
                        //         navigate("/setupMFA2nd");
                        //     }
                        // }
                        // if (!SAMLRequest && !RelayState) {
                            if(res.data?.mfa_type === 'PASSKEY' && res.data?.passKey_status === 'REGISTERED'){
                                navigate('/verifyPasskey', {state: {passkeyStatus: res.data?.passKey_status, userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data}})
                            }else if(res.data?.mfa_type === 'EMAIL'){
                                navigate('/passcode', {state: {userMfa: res.data?.mfa_type, countVal: 90, verifyStatus: res.data?.verifyStatus}})
                            }else {
                                navigate("/setupMFA2nd",{state: {passkeyStatus: res.data?.passKey_status, userMfa: res.data?.mfa_type, passkeyData: res.data?.passkey_data}});
                            }
                        // }
                    }
                    else if (res.data.verifyStatus === 'QR_COMPLETE') {
                        navigate("/setupMFA?step=3");
                    }
                    else if (res.data.verifyStatus === 'UNVERIFIED') {
                        navigate("/setupMFA?step=1");
                    }
                    else if (res.data.verifyStatus === 'LOCKED') {
                        console.log("You are LOCKED")
                    }
                    let args = (Object.values(callbackArguments))
                    if (successCallback) {
                        successCallback(args[0], args[1], args[2], token)
                    }
                }
                else if (res.status === 401) {
                    setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                    errorText.current.innerHTML = 'User does not exist. Please contact your admin.'
                }
                else if (res.status === 403) {
                    setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                    errorText.current.innerHTML = 'Sorry, you are not authorized to access this application.'
                }
                else {
                    setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                    errorText.current.innerHTML = 'Something went wrong! Please try again later.'
                }
            }).catch((e) => {
                if(e.message==='Network Error'){
                    setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                    errorText.current.innerHTML = 'Sorry, we are unable to locate your identity. Please login to Zaperon Client App and try again.'
                    setIsLoading(false)
                } else if (e.response?.status === 409) {
                    navigate("/accessBlock", { state: { ...e.response.data, email } });
                  } else{
                    setIsLoading(false)
                    setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                    errorText.current.innerHTML = 'Something went wrong! Please try again later.'
                }
            })
        }
        

    }

    // Snackbar
    useEffect(() => {
        if(cookies.USER_EMAIL?.email){
            setEmail(cookies.USER_EMAIL?.email)
        }
        // eslint-disable-next-line
    },[])
    return (
       <>
        <Box sx={{
                height:{sm: '85vh',xs: "75vh" },  display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center"
            }}>
                <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '8.8vh', sm: '6.5vh', xs: "15vh" } }}><LogoFallback /></Box>
                <Box sx={{ marginBottom: '16px', background: `#EFEFEF`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: {sm: "40px", xs: "26px"} }}>


                    {/* <Box sx={{ width: { md: '50%', sm: '50%' }, height: { md: '50%', sm: '50%' }, }}> */}
                    <Box
                    component="img"
                    sx={{
                        height: { xs: 48, sm: 80 },
                        width: { xs: 48, sm: 80 },
                    }}
                    alt="person"
                    src={timeout}
                    />
                    {/* </Box> */}


                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '3vh' }}>
                    <Typography sx={{
                        font: { xs: "normal normal bold 16px/20px Open Sans", sm: 'normal normal bold 32px/43px Open Sans' }, color: '#0B3558', marginBottom: '8px'
                    }}>
                        {"Your Session Has Expired!"}
                    </Typography>
                    <Typography sx={{
                        font: { sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/16px Open Sans" }, color: '#0B3558', width: 'auto', textAlign: 'center'
                    }}>
                        {"Please enter your email again to continue"}<br />
                        {"using your workspace."}
                    </Typography>
                </Box>
                <Box>
                    {/* Email Input Field */}
                    <form onSubmit={handleSubmit}>
                        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%"}}>
                            <InputTextField
                                value={email || ""}
                                type='email'
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    if (!e.target.value || e.target.value.match(regex)) {
                                        setErrorColors({ fieldset: 'rgba(0, 0, 0, 0.23)', labelNonFocus: 'rgba(0, 0, 0, 0.6)', labelFocus: '#003FB9' })
                                        errorText.current.innerHTML = ''
                                    }

                                }}
                                size='small'
                                InputProps={{
                                    sx: {
                                        font: {xs: 'normal normal 600 14px/15px Open Sans', sm:'normal normal 600 14px/19px Open Sans'}, color: '#0B3558',
                                        ".css-1d3z3hw-MuiOutlinedInput-notchedOutline:active": {
                                            border: '2px solid #003FB9', borderRadius: '4px'
                                        },
                                        ".css-14lo706": {
                                            color: '#0B3558', width: '90px '
                                        },
                                        '.css-1x5jdmq': {
                                            padding: {sm: '10px 0 0 16px', xs:'10px 0 10px 16px' }
                                        },
                                        '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input':{
                                            padding: {sm: '10px 0 0 16px', xs:'10px 0 10px 16px' }
                                        },
                                        height: {sm: "41px", xs: "40px"}
                                    },
                                }}

                                sx={{
                                    "& .fieldset.css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                        borderColor: errorColors.fieldset //feildset color
                                    },
                                    "& .label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                        color: errorColors.labelNonFocus, //email address label color,
                                        font: {xs: 'normal normal 600 12px/15px Open Sans', sm:'normal normal 600 14px/19px Open Sans'},
                                        paddingRight: '4px',
                                        backgroundColor: 'white',
                                    },
                                    "& .label.Mui-focused": {
                                        color: errorColors.labelFocus //email address label color
                                    },
                                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                        color: '#A2A2A2', fontSize: {sm:'14px', xs: "12px"}, fontWeight: '300'
                                    },
                                    '& .css-1x5jdmq': {
                                            padding: {sm: '10px 0 0 16px', xs:'10px 0 14px 16px' }
                                        },
                                    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input':{
                                        padding: {sm: '10px 0 0 16px', xs:'10px 0 14px 16px' }
                                    },
                                 
                                    margin: 0, width:{sm: "360px", xs: "300px"}, marginBottom: '4px', font: {xs: 'normal normal normal 12px/15px Open Sans', sm: 'normal normal normal 14px/19px Open Sans'},
                                    color: '#A2A2A2'
                                    , borderRadius: '4px', background: '#FFFFFF 0% 0% no-repeat padding-box', padding: '0'
                                }} label="Email Address" />
                            {/* Next Button */}
                            <p ref={errorText} style={{ font: 'normal normal 600 16px/22px Open Sans', margin:"0px 0px 0px 0px", color: '#D9292B', textAlign: '', padding: '0'}} className='errorPara'></p>
                        </Box>
                        <Button
                            disabled={false}
                            type='submit'
                            variant="contained"
                            onClick={() => {
                                if (email && email.match(regex)) {
                                    setIsLoading(true)
                                    setErrorColors({ fieldset: 'rgba(0, 0, 0, 0.23)', labelNonFocus: 'rgba(0, 0, 0, 0.6)', labelFocus: '#003FB9' })
                                    errorText.current.innerHTML = ''
                                    // if (SAMLRequest && RelayState) {
                                    //     samlrequestHandler(email, SAMLRequest, RelayState)
                                    // }
                                    // else {
                                        getUserAgentStatus()
                                        // clickHandler(false)
                                    // }
                                }
                                else {
                                    setErrorColors({ fieldset: '#D9292B !important', labelNonFocus: '#D9292B', labelFocus: '#D9292B' })
                                    errorText.current.innerHTML = 'Please enter a valid email.'
                                }
                            }}
                            sx={{ marginTop: '1.85vh', textTransform: 'capitalize', background: '#003FB9 0% 0% no-repeat padding-box', boxShadow: '0px 3px 6px #00000029', borderRadius: '4px', height: { md: '43px', sm: '43px', xs: "43px" }, width: {sm: '360px', xs: "300px"},marginLeft: "auto", marginRight: "auto", border: 'none' }}>
                            <Typography sx={{ width: 'fit-content', font: { sm: 'normal normal 600 14px/19px Open Sans' }, color: '#FFFFFF', fontWeight: 600 }}>
                                {isLoading
                                    ?
                                    <CircularProgress style={{ width: '32px', height: '32px', color: '#FFFFFF' }} />
                                    :
                                     `Continue`
                                }
                            </Typography>
                        </Button>
                    </form>
                </Box>
            </Box >
            <Footer />
       </>
    )
}

export default SessionTimeout