import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useCookies } from "react-cookie";
import { checkPlatform } from "../util/IsClientRunning";

const Ssoserviceredirect = () => {
  const location = useLocation();
  const [cookies] = useCookies()
  const { url, SAMLResponse, RelayState } = queryString.parse(location.search);
  const SAMLReq = cookies.SAML
  const RelayReq = cookies.RELAY

  useEffect(() => {
  window.document.forms[0].submit()
  }, []);

  return (
    <form action={url} method="post">
    {checkPlatform() !== false? <>
      <input type="hidden" name="SAMLResponse" value={SAMLResponse} />
      <input type="hidden" name="RelayState" value={RelayState} />
    </>: <>
      <input type="hidden" name="SAMLResponse" value={SAMLReq || localStorage.getItem('saml')} />
      <input type="hidden" name="RelayState" value={RelayReq || localStorage.getItem('relay')} />
    </>
    }
      
    </form>
  );
};

export default Ssoserviceredirect;