const LoginUrl = process.env.REACT_APP_BASE_URL_LOGIN;
export const OauthUrl = process.env.REACT_APP_OAUTH_URL
export const BaseUrl = process.env.REACT_APP_BASE_URL;
// const BaseUrl = process.env.REACT_APP_BASE_URL;
export const loginUrl = (email) => {
  return LoginUrl + "zaperon/login/" + email;
};
export const getUserAgentStatusUrl = () => {
  return BaseUrl + 'getUserAgentStatus'
}
export const passcodeUrl = () => {
  return BaseUrl + 'login/passcode'
}
export const resendAccessEmailUrl = (email) => {
  return BaseUrl + 'resendAccessEmail?user_email='+email
}
export const storePasskeyUrl = () =>{
  return BaseUrl + 'passkey/storePasskeyDetails'
}
export const verifyPasskeyUrl = () =>{
  return BaseUrl + 'passkey/verifyPassKeyDetails'
}
export const verifyAccountWithEmailUrl = () => {
  return BaseUrl + 'zaperon/verifyAccountWithEmail'
}
export const activateAccountWithEmailUrl = () => {
  return BaseUrl + 'zaperon/activateAccountWithEmail'
}
export const resendCodeWithEmailUrl = () => {
  return BaseUrl + 'zaperon/resendCodeWithEmail'
}