import { Box, Typography } from '@mui/material';
import React from 'react'
import Lottie from 'react-lottie';
import animation from "../assets/ic_redirect_loader.json";
import Footer from '../components/Footer.js';
import LogoFallback from '../components/LogoFallback.js';
function Redirect() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', overflow: 'hidden' }}>
            <Box sx={{ width: '180px', height: '90px', marginTop: 'calc(15vh - 54px)',position:'absolute',top:0 }}><LogoFallback/></Box>
                <Box sx={{
                    width: {sm: '500px', xs: "fit-content"}, height: '85vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                }}>

                    <Lottie
                        options={defaultOptions}
                        height={180}
                        width={300}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography sx={{
                        font: { md: 'normal normal bold 48px/65px Open Sans', lg: 'normal normal bold 5.55vh/7.52vh Open Sans', sm: 'normal normal bold 32px/43px Open Sans' }, color: '#0B3558', marginBottom: '8px'
                    }}>
                            Redirecting...
                        </Typography>
                        <Typography sx={{
                        font: { md: 'normal normal normal 20px/27px Open Sans', lg: 'normal normal normal 2.3vh/3.1vh Open Sans', sm: 'normal normal normal 20px/27px Open Sans' }, color: '#0B3558', width: 'auto', textAlign: 'center'
                    }}>
                            You are being redirected to your<br /> workspace
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <div style={{height:'15vh'}}>
                <Footer />
            </div>
        </>
    )
}

export default Redirect