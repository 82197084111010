import { Box, Typography } from '@mui/material'
import React, { useRef, useEffect, useState } from 'react'
// import SetupMFAListItem from '../components/SetupMFAListItem'
import verifyAccountImg from "../assets/ic_verift_account.svg"
import Footer from "../components/Footer"
import './SetupMFA.css'
import $ from 'jquery'
import LocationPopup from '../util/LocationPopup';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import CircularProgress from '@mui/material/CircularProgress';
import { checkPlatform } from '../util/IsClientRunning';
import { errorHandling, getJWKToken, passkeySupportCheck, removeCookie } from '../util/action';
import "../styles/loginBox.css"
import jwtDecode from 'jwt-decode';
import { OauthUrl } from '../util/api';
import LogoFallback from '../components/LogoFallback';

const BaseUrl = process.env.REACT_APP_BASE_URL

const responsive = {
    totalMarginLeft: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center"
    },
    logoMargins: {
        marginTop: 'calc(15vh - 54px)',
        marginBottom: { md: '7.4vh', sm: '6.5vh' },
        height: '5.27vh'
    },
    setupMFAtext: {
        fontWeight: 800,
        font: { xl: 'normal normal bold 48px/65px Open Sans', sm: 'normal normal bold 40px/50px Open Sans' },
        color: '#0B3558',
        marginBottom: '1%'
    },
    textUnderSetupMFA: {
        font: { xl: 'normal normal normal 24px/33px Open Sans', sm: 'normal normal normal 20px/25px Open Sans' },
        color: '#0B3558',
        marginBottom: '6%',
    },
    setupMFAListItem: {
        box: {
            marginBottom: '2.5%'
        },
        icon: {
            borderRadius: '50%',
            width: '64px',
            height: '64px',
            font: { xl: 'normal normal bold 24px/33px Open Sans', sm: 'normal normal bold 20px/25px Open Sans' },
        },
        text: {
            width: 'fit-content',
            marginLeft: '16px',
            font: { xl: 'normal normal bold 24px/33px Open Sans', sm: 'normal normal bold 20px/25px Open Sans' },
        }
    },
    verifyScreenShot: {
        width: { xl: '400px', sm: '300px' },
        height: { xl: '400px', sm: '300px' },
        backgroundColor: '#EFEFEF',
        marginBottom: '8%',
    },
    textInsideVerifyScreenShot: {
        color: '#A2A2A2',
        font: 'italic normal normal 20px/27px Open Sans',
    },
    firstInstruction: {
        font: { xl: 'normal normal normal 20px/27px Open Sans', sm: 'normal normal normal 16px/20px Open Sans' },
        color: '#0B3558',
        width: '530px',
        letterSpacing: '0px',
        marginBottom: '32px'
    },
    firstButton: {
        width: { xl: '400px', sm: '300px' },
        height: '61px',
        background: '#003FB9 0% 0% no-repeat padding-box',
        borderRadius: '4px'
    },
    textInsideButton: {
        font: { xl: 'normal normal 600 24px/33px Open Sans', sm: 'normal normal 600 20px/25px Open Sans' },
        color: '#FFFFFF'
    },
    secondPageBox: {
        width: '100%',
        height: 'auto'
    },
    QRcode: {
        width: { xl: '397px', sm: '300px' },
        height: { xl: '397px', sm: '300px' },
    },
    theORtext: {
        font: { xl: 'normal normal bold 20px/27px Open Sans' },
        color: '#A2A2A2',
        marginLeft: '7%',
        marginRight: '7%'
    },
    insideQRCodeRightHalf: {
        width: { xl: '318px', sm: '200px' },
        height: { xl: '135px' },
    },
    insideQRCodeRightHalfSubpartOne: {
        width: { xl: '300px', sm: '200px' },
        height: { xl: '55px' },
        marginBottom: '5%'
    },
    insideQRCodeRightHalfSubpartOneText: {
        font: { xl: 'normal normal bold 24px/33px Open Sans', sm: 'normal normal bold 16px/25px Open Sans' },
        color: '#0B3558',
        letterSpacing: '2.4px'
    },
    insideQRCodeRightHalfSubpartTwo: {
        width: { xl: '340px', sm: '200px' },
        height: { xl: '55px' },
        font: { xl: 'italic normal normal 20px/27px Open Sans', sm: 'italic normal normal 14px/18px Open Sans' },
        color: '#A2A2A2',
        letterSpacing: 0
    },
    scanQRcodeText: {
        width: { xl: '326px' },
        height: { xl: '65px' },
        font: { xl: 'normal normal bold 48px/65px Open Sans', sm: 'normal normal bold 36px/65px Open Sans' },
        color: '#0B3558',
        marginBottom: '2%',
        marginTop: '4%'
    },
    scanQRcodeText1: {
        font: { xl: 'normal normal normal 20px/27px Open Sans' },
        color: '#0B3558',
        marginBottom: '4%'
    },
    scanQRcodeText2: {
        font: { xl: 'normal normal 600 18px/24px Open Sans' },
        color: '#003FB9'
    },
    verifyAccount: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    verifyAccountImg: {
        width: '50%',
        height: '50%'
    },
    verifyAccountImgBox: {
        width: { md: '200px', sm: '18.5vh' },
        height: { md: '200px', sm: '18.5vh' },
        borderRadius: '50%',
        backgroundColor: '#EFEFEF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '3%',
    },
    verifyAccountText1: {
        font: { md: 'normal normal bold 32px/43px Open Sans', lg: 'normal normal bold 32px/43px Open Sans', sm: 'normal normal bold 32px/43px Open Sans', xs: "normal normal bold 16px/25px Open Sans" },
        color: '#0B3558',
        marginBottom: '8px'
    },
    verifyAccountText2: {
        font: { md: 'normal normal normal 16px/22px Open Sans', lg: 'normal normal normal 16px/22px Open Sans', sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/15px Open Sans" },
        letterSpacing: 0,
        color: '#0B3558',
        width: 'auto',
        textAlign: 'center',
        marginBottom: { md: '32px', sm: '2.77vh', xs: "4vh" },
    },
    verifyAccountText3: {
        font: { xl: 'normal normal bold 112px/153px Open Sans', sm: 'normal normal bold 94px/130px Open Sans', xs: 'normal normal bold 12px/15px Open Sans' },
        letterSpacing: 0,
        color: '#003FB9', marginBottom: '5%'
    },
    verifyAccountText4: {
        font: { xl: 'normal normal normal 20px/27px Open Sans', sm: 'normal normal normal 16px/16px Open Sans' },
        letterSpacing: 0,
        color: '#0B3558', width: '100%', textAlign: 'center', marginBottom: '1%'
    },
    verifyAccountText5: {
        font: { xl: 'normal normal 600 20px/27px Open Sans', sm: 'normal normal 600 16px/16px Open Sans' },
        letterSpacing: 0,
        color: '#003FB9', width: '100%', textAlign: 'center'
    },
    entercode: {
        width: "55px",
        height: "55px",
        textAlign: "center",
        margin: '0 8px 2.77vh 8px',
        border: "2px solid #003FB9",
        borderRadius: "4px",
        font: "normal normal 600 32px/ 43px Open Sans",
        color: "#0B3558",
    }

}

let userLocation = { lat: '', long: '', accuracy: "" }
let locationId;
let countOfWrongCode = 0;
// eslint-disable-next-line
let userInput6DigitCode = ''

function SetupMFA_2nd() {
    const [isInputFocused, setIsInputFocused] = useState(false);
    const location = useLocation();
    const {userMfa, passkeyStatus}=location.state;
    const { url, SAMLResponse, RelayState, SAMLRequest, username, type, clientId } = queryString.parse(location.search);
    const [loading, setLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [cookies, setCookie] = useCookies("");
    const incorrectError = useRef()
    const navigate = useNavigate()
    const locationSuccessCallback = (position) => {
        if (!userLocation.accuracy || userLocation.accuracy === '' || userLocation.accuracy > position.coords.accuracy) {
            userLocation = { lat: position.coords.latitude, long: position.coords.longitude, accuracy: position.coords.accuracy }
        }
        setShowPopup(false)
    };

    const locationErrorCallback = (error) => {
        if (checkPlatform() === 'Mobile') {
            return setShowPopup(false)
        }
        if(error?.code === 1){
            setShowPopup(true)
        }
        console.log(error, error?.message);
    };
    const fetchLocation = () => {
        setShowPopup(false)
        locationId = navigator.geolocation.watchPosition(locationSuccessCallback, locationErrorCallback);
    };
    const logout = () => {
        let token = cookies.USER_AUTH.Token
        let date = new Date();
        setCookie('USER_AUTH', {}, { expires: date });
        setCookie('USER_EMAIL', {}, { expires: date });
        setCookie('FIRST', {}, { expires: date });
        axios.get(BaseUrl + 'logout/user-dashboard', {
            headers: {
                "Authorization": token
            }
        })
            .then(() => {
                navigate('/login')
            })
            .catch(() => {
                navigate('/login')
            })
    }
    // const getGeolocation = async (lat, long) => {
    //     try {
    //         setLoading(true)
    //         let latitude = lat
    //         let longitude = long
    //         let data = await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
    //         console.log(data, ' geolocatoin data ', longitude)
    //         if (data && data.status === 200) {
    //             return data.data;
    //         }else {
    //             setLoading(false)
    //         }
    //     } catch (error) {
    //         setLoading(false)
    //         console.log(error)
    //     }
    // }
    const handleInputChange = (e, nextInputId, prevInputId) => {
        const input = e.target;
        const value = input.value;
        if (e.key === 'Backspace' && value.length === 0 && prevInputId) {
            document.getElementById(prevInputId).focus();
        } else if (value.length === 1 && nextInputId) {
            document.getElementById(nextInputId).focus();
        }

    };

    React.useEffect(() => {
        let token = cookies.USER_AUTH?.Token
        if (!token) {
            navigate("/login")
        }
        // to get location
        if (checkPlatform() === true) {
            navigator.permissions && navigator.permissions.query({ name: 'geolocation' })
                .then(function (PermissionStatus) {
                    if (PermissionStatus.state === 'granted') {
                        //allowed
                        fetchLocation()
                    } else if (PermissionStatus.state === 'prompt') {
                        // prompt - not yet granted or denied
                        setShowPopup(true)
                    } else {
                        //denied
                        setShowPopup(true)
                    }
                })
        }
        else if (checkPlatform() === 'Mobile') {
            fetchLocation()
        }
        //for 6digit inputs
        $('.code-group2').find('input').each(function () {
            $(this).attr('maxlength', 1);
            $(this).on('keyup', function (e) {
                var parent = $($(this).parent());

                if (e.keyCode === 8 || e.keyCode === 37) {
                    var prev = parent.find('input#' + $(this).data('previous'));

                    if (prev.length) {
                        $(prev).select();
                    }
                } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                    var next = parent.find('input#' + $(this).data('next'));

                    if (next.length) {
                        $(next).select();
                    } else {
                        if (parent.data('autosubmit')) {
                            parent.submit();
                        }
                    }
                }
            });
        });
        // eslint-disable-next-line
    }, [])
    const navigateToSSORedirect = async (email, saml, relay, token) => {
        axios.post(BaseUrl + 'ssologin?username=' + encodeURIComponent(email) + '&SAMLRequest=' + encodeURIComponent(saml) + '&RelayState=' + encodeURIComponent(relay), '_self', {
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            if (res.status === 200) {
                let url = res.data.acsUrl;
                let SAMLResponse = res.data.SAMLResponse;
                let relayState = res.data.RelayState;
                // if (firstTimeUser != "false") {
                //     navigate(`/setupMFA2nd?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`)
                // } else {
                if (checkPlatform() === false) {
                    setCookie('RELAY', relayState, { expires: new Date(Date.now() + 86400000) })
                    setCookie('SAML', SAMLResponse, { expires: new Date(Date.now() + 86400000) })
                    localStorage.setItem('saml', SAMLResponse)
                    localStorage.setItem('relay', relayState)
                }

                navigate(`/Ssoserviceredirect?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(relayState)}`)
                // }
            }
            else if (res.status === 401 || res.status === 400) {
                if (res.data === 'UNVERIFIED') {
                    navigate("/login")
                }
                else if (res.data === 'QR_COMPLETE') {
                    navigate("/setupMFA?step=3")
                }
                else if (res.data === 'VERIFICATION_COMPLETE') {
                    navigate("/setupMFA")

                }

                else {
                    navigate("/login")
                }


                // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
            }
            else if (res.status === 403 || res.status === 405) {
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
        }).catch((error) => {
            // 401 status means user hasn't verified with the otp
            if (error.response.status === 403 || error.response.status === 405) {
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
            else if (error.response.data.error === 'Unauthorized') {
                navigate("/sessionTimeout")
            }
            else if (error.response.data === 'UNVERIFIED') {
                navigate("/setupMFA?step=1")
            }
            else if (error.response.data === 'QR_COMPLETE') {
                navigate("/setupMFA?step=3")
            }
            else if (error.response.data === 'VERIFICATION_COMPLETE') {
                navigate(`/setupMFA2nd?username=${encodeURIComponent(username)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`)
            } else if (error.response?.status === 409) {
                navigate("/accessBlock", { state: { ...error.response.data, email } });
            } else {
                navigate("/login")
            }
            incorrectError.current.innerHTML = "Something went wrong! Please contact your admin"

            // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
        })
    }
    const jwkTokenData = async (token) => {
        if(!cookies.USER_OAUTH?.Token){
            let data = await getJWKToken(token, clientId)
            if(data && data.status === 200){
                let jwk = jwtDecode(data.data?.access_token)
                setCookie('USER_OAUTH', {Token: data.data?.access_token}, {expires: new Date(jwk.exp * 1000)})
                window.location.href = `${OauthUrl}login?access_token=${data.data?.access_token}`;
                return data
            }else if(data && data.status === 403){
                return navigate('/unauthorised', {state: {back: true}})
            }
        }else {
            window.location.href = `${OauthUrl}login?access_token=${cookies.USER_OAUTH?.Token}`;
        }
        
    } 
    async function continueHandler(userEnteredCode) {

        let token = cookies.USER_AUTH?.Token
        let gpsEnabled = userLocation.accuracy < 100 ? "yes" : "no"
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        var raw;
        console.log('location data: ', userLocation)
        if (userLocation.lat && userLocation.long && userLocation.lat !== "" && userLocation.long !== "") {
            // let { city, countryName, principalSubdivision } = await getGeolocation(userLocation.lat, userLocation.long)
            raw = {
                "totp": userEnteredCode,
                "location": userLocation.lat + "," + userLocation.long,
            "gpsEnabled": gpsEnabled,
                // city: city,
                // state: principalSubdivision,
                // country: countryName,
            };
        } else {
            raw = {
                "totp": userEnteredCode,
                "location": "0,0",
                "gpsEnabled": "no",
            }
        }
        if(userMfa === 'PASSKEY'){
            raw.passkey_status = await passkeySupportCheck()
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: 'follow'
        };
        setLoading(true)
        fetch(BaseUrl + 'zaperon/verifyCodeMatchLocation', requestOptions)
            .then((res) => {
                
                if (res.status === 200) {
                    if (locationId) {
                        navigator.geolocation.clearWatch(locationId);
                    }
                    if (SAMLRequest && RelayState && username) {
                        return navigateToSSORedirect(username, SAMLRequest, RelayState, cookies.USER_AUTH?.Token)
                    }else if (type === 'oauth2'){
                        return jwkTokenData(cookies.USER_AUTH?.Token)
                    }
                    else if (url && SAMLResponse && RelayState) {
                        return window.location.assign(`/Ssoserviceredirect?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`);
                    } else if (checkPlatform() === 'Mobile') {
                        return navigate('/dashboard')
                    } else {
                        if(userMfa === 'PASSKEY'){
                            if(raw.passkey_status === 'NOT_SUPPORTED'){
                                return navigate('/dashboard')
                            }else if(passkeyStatus === 'NOT_REGISTERED'){
                                return navigate('/createPasskey', {state: {...location.state}})
                            } else {
                                return navigate('/verifyPasskey', {state: {...location.state}})
                            }
                        }else {
                        let homeUrl = localStorage.getItem("zap_panel")
                        if (homeUrl && homeUrl !== 'dashboard') {
                            window.location.href = `/${homeUrl}`
                        }
                        else {
                            window.location.href = `/dashboard`
                        }
                    }
                    }
                }
                else if (res.status === 409) {
                    let err = errorHandling(res.status)
                    if (err === 409) {
                        if (SAMLRequest && RelayState && username) {
                            return navigate(`/accessBlock?username=${encodeURIComponent(username)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`, { state: { ...res.data, email: cookies.USER_EMAIL?.email } });
                        } else {
                            return navigate("/accessBlock", { state: { ...res.data, email: cookies.USER_EMAIL?.email } });
                        }
                    }
                } else if (res.status === 403) {
                    removeCookie('USER_AUTH')
                    navigate('/unauthorised')
                } else if (res.status === 401) {
                    navigate('/sessionTimeout')
                }
                else if (res.status === 400 || res.status === 500) {
                    throw res;
                }
                else {
                    if (countOfWrongCode > 2) {
                        logout()
                    }
                    countOfWrongCode = countOfWrongCode + 1
                    incorrectError.current.innerHTML = "Incorrect code, Please try again"
                }
                setTimeout(() => setLoading(false),300)

            }).catch(function (error) {

                console.log(error.response?.status, ' inside catch!', error)
                if (countOfWrongCode > 2) {
                    logout()
                }
                countOfWrongCode = countOfWrongCode + 1
                if(error?.status === 400){
                    error.text().then((re) => {
                        if(re === 'Incorrect OTP'){
                        incorrectError.current.innerHTML = "Incorrect code, Please try again"
                        }else {
                        incorrectError.current.innerHTML = "Your mobile device is not in proximity. Please move it closer and try again."
                        }
                    }).catch(err => console.log(err))
                }else if(error?.status === 500){
                    error.json().then((re) => {
                        if(re?.message === 'Location Failed'){
                        incorrectError.current.innerHTML = "We are unable to get your location. Please try again."
                        }else {
                        incorrectError.current.innerHTML = "Something went wrong! Please contact your admin"
                        }
                    }).catch(err => console.log(err))
                }
                else if (error.response?.status === 401 || error.response?.status === 409 || error.response?.status === 403) {
                    let err = errorHandling(error.response?.status)
                    if (err === 409) {
                        if (SAMLRequest && RelayState && username) {
                            return navigate(`/accessBlock?username=${encodeURIComponent(username)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`, { state: { ...error.response?.data, email: cookies.USER_EMAIL?.email } });
                        } else {
                            navigate("/accessBlock", { state: { ...error.response?.data, email: cookies.USER_EMAIL?.email } });
                        }
                    }
                }else {
                    incorrectError.current.innerHTML = "Something went wrong! Please contact your admin"
                }
                setLoading(false)
            });
    }
    const checkFocus = () => {
        const inputs = document.querySelectorAll('.enterCode');
        let isFocused = false;
        inputs.forEach(input => {
            if (document.activeElement === input) {
                isFocused = true;
            }
        });
        setIsInputFocused(isFocused);
    };
    useEffect(() => {
        const inputs = document.querySelectorAll('.enterCode');
        
        inputs.forEach(input => {
            input.addEventListener('focus', checkFocus);
            input.addEventListener('blur', checkFocus);
        });
    
        // Cleanup event listeners
        return () => {
            inputs.forEach(input => {
                input.removeEventListener('focus', checkFocus);
                input.removeEventListener('blur', checkFocus);
            });
        };
    }, []);
    return (<>
        {showPopup &&
            <LocationPopup clickHandler={fetchLocation} />}
        <Box sx={{
            ...responsive.totalMarginLeft, height: { sm: "85vh", xs: "75vh" }
        }}>
            <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '8.8vh', sm: '6.5vh', xs: "calc(15vh - 70px)" }, marginTop: { xs: "5vh", sm: "5vh" } }}><LogoFallback /></Box>
            {/* Main */}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ ...responsive.verifyAccount }}>
                    {/* <Box sx={{ ...responsive.verifyAccountImgBox }}>
                        <img src={verifyAccountImg} style={{ ...responsive.verifyAccountImg }} alt="" />
                    </Box> */}
                    <Box sx={{ marginBottom: '3%', background: `#EFEFEF`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: { sm: "40px", xs: "26px" } }}>
                        <Box
                            component="img"
                            sx={{
                                height: { xs: 48, sm: 80 },
                                width: { xs: 48, sm: 80 },
                            }}
                            alt="person"
                            src={verifyAccountImg}
                        />
                    </Box>
                    <Typography sx={{ ...responsive.verifyAccountText1 }}>Verify Your Account</Typography>
                    <Typography sx={{ ...responsive.verifyAccountText2 }}>Enter the 6-digit code that you see on<br /><span style={{ fontWeight: 600 }}>Zaperon Verify App</span> to continue</Typography>
                    <div>
                        <form
                            className="code-group2"
                            data-group-name="digits"
                            data-autosubmit="false"
                            autoComplete="off"
                            id="6digitform"
                            style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                for (let i = 0; i <= 5; i++) {
                                    userInput6DigitCode += e.target[i].value
                                }
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <input type="text" id="digit-1" pattern="\d*" inputMode='numeric' onKeyUp={(e) => handleInputChange(e, 'digit-2')} name="digit-1" data-next="digit-2" className={'enterCode'} />
                                <input
                                    type="text"
                                    id="digit-2"
                                    name="digit-2"
                                    data-next="digit-3"
                                    pattern="\d*"
                                    data-previous="digit-1"
                                    inputMode='numeric'
                                    className={'enterCode'}
                                    onKeyUp={(e) => handleInputChange(e, 'digit-3', 'digit-1')}
                                />
                                <input
                                    type="text"
                                    id="digit-3"
                                    pattern="\d*"
                                    name="digit-3"
                                    inputMode='numeric'
                                    data-next="digit-4"
                                    data-previous="digit-2"
                                    onKeyUp={(e) => handleInputChange(e, 'digit-4', 'digit-2')}
                                    className={'enterCode'}
                                />
                                <input
                                    type="text"
                                    id="digit-4"
                                    name="digit-4"
                                    inputMode='numeric'
                                    data-next="digit-5"
                                    onKeyUp={(e) => handleInputChange(e, 'digit-5', 'digit-3')}
                                    data-previous="digit-3"
                                    className={'enterCode'}
                                />
                                <input
                                    type="text"
                                    id="digit-5"
                                    pattern="\d*"
                                    name="digit-5"
                                    inputMode='numeric'
                                    data-next="digit-6"
                                    onKeyUp={(e) => handleInputChange(e, 'digit-6', 'digit-4')}
                                    data-previous="digit-4"
                                    className={'enterCode'}
                                />
                                <input type="text" inputMode='numeric' onKeyUp={(e) => handleInputChange(e, 'digit-6', 'digit-5')} pattern="\d*" id="digit-6" name="digit-6" data-previous="digit-5" className={'enterCode'} />
                            </div>
                            <button type="submit" className="nxtBtn codeSubmit" onClick={() => {
                                if(loading === false){
                                let form = document.getElementById('6digitform').getElementsByTagName("input")
                                let userInput6DigitCode = '';
                                for (let i = 0; i <= 5; i++) {
                                    userInput6DigitCode += form[i].value
                                }
                                // console.log(userInput6DigitCode)
                                if (checkPlatform() !== false) {
                                    userInput6DigitCode = userInput6DigitCode.trim()
                                    userInput6DigitCode = userInput6DigitCode.replaceAll(' ', '')
                                }

                                if (userInput6DigitCode.length !== 6) {
                                    incorrectError.current.innerHTML = "Please Enter Valid Code"
                                } else {
                                    incorrectError.current.innerHTML = ""
                                    continueHandler(userInput6DigitCode);
                                }
                            }
                            }
                            }>
                                <Typography sx={{ font: { sm: 'normal normal 600 16px/19px Open Sans' }, color: '#FFFFFF', fontWeight: 600, textAlign: 'center' }}>
                                    {loading
                                        ?
                                        <CircularProgress style={{ width: '24px', height: '24px', color: '#FFFFFF' }} />
                                        // <lottiePlayer src={loadingImg} background="transparent" speed="1" style={{width:'100%',height:'100%'}} loop autoPlay></lottiePlayer>
                                        :
                                        `Continue`
                                    }
                                </Typography>
                            </button>
                            <p style={{ ...responsive.verifyAccountText2, marginTop: '1.48vh', marginBottom: '8px', color: '#D9292B', font: 'normal normal normal 14px/20px Open Sans', width:"360px", textAlign: "center" }} className="erroPara" ref={incorrectError}></p>
                        </form>
                    </div>

                </Box>
            </Box>
        </Box>
        {(!isInputFocused || checkPlatform() === true) && <Footer />}
    </>
    )
}

export default SetupMFA_2nd
