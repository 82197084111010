import axios from "axios";
import base64js from 'base64-js' 
import { activateAccountWithEmailUrl, getUserAgentStatusUrl, loginUrl, passcodeUrl, resendAccessEmailUrl, resendCodeWithEmailUrl, storePasskeyUrl, verifyAccountWithEmailUrl, verifyPasskeyUrl } from "./api";
const BaseUrl= process.env.REACT_APP_BASE_URL
export function removeCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
export const loginAction = async (email) => {
  try {
    let data  = await axios.get(loginUrl(email));
    return data;
  } catch (error) {
    return error.response;
  }
};
export const checkCookie = () => {
  // Get all cookies from the site
  const cookies = document.cookie;

  // Check if the cookie string includes 'AGENT_LESS'
  return cookies.includes('AGENT_LESS');
}
export const errorHandling = (err) => {
  localStorage.setItem("zap_panel", "dashboard")
   if(err === 401){
    removeCookie('USER_AUTH')
    window.location.href = "/sessionTimeout"
  }
  else if(err === 409){
    return err;
  }else if(err === 403){
    window.location.href = "/unauthorised"
  }
}

export const getJWKToken = async(token, clientId) => {
  try {
      let data = await axios.get(`${BaseUrl}zaperon/verified/oauth2Token?client_id=${clientId}`, {
          headers: {
              'Authorization': token
          }
      })
      if(data && data.status === 200){
          return data
      }
  } catch (error) {
      console.log(error)
      return error.response
  }
}
export const resendAccessEmailAction = async(email) => {
  try {
      let data = await axios.get(resendAccessEmailUrl(email))
      if(data && data.status === 200){
          console.log(data)
          return data
      }
  } catch (error) {
      console.log(error)
      return error.response
  }
}
export const passcodeAction = async(obj) => {
  try {
      let data = await axios.post(passcodeUrl(), obj)
      if(data && data.status === 200){
          return data
      }
  } catch (error) {
      console.log(error)
      errorHandling(error.response?.status)
      return error.response
  }
}
export const storePasskeyAction = async(token, payload, navigate) => {
  try {
    const formData = new FormData();
    formData.append('credential', JSON.stringify(payload));
    console.log(formData)
      let data = await axios.post(storePasskeyUrl(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`
      }
      })
    console.log(formData)

      if(data && data.status === 200){
          return data
      }
  } catch (error) {
      console.log(error, typeof error)
      if(typeof error === 'string'){
        return error
      }
      if(error?.response?.status === 403){
        return navigate('/unauthorised', {state: {one: true}})
      }
      errorHandling(error.response?.status)
      return error.response
  }
}
export const verifyPasskeyAction = async(token, payload, navigate) => {
  try {
    const formData = new FormData();
    formData.append('credential', JSON.stringify(payload));
      let data = await axios.post(verifyPasskeyUrl(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`
      }
      })
      if(data && data.status === 200){
          return data
      }
  } catch (error) {
      console.log(error)
      if(error.response.status === 403){
        return navigate('/unauthorised',{state: {one: true}})
      }
      errorHandling(error.response?.status)
      return error.response
  }
}
export const getUserAgentStatusAction = async(obj) => {
  try {
      let data = await axios.post(getUserAgentStatusUrl(), obj)
      if(data && data.status === 200){
          return data
      }
  } catch (error) {
      console.log(error)
      return error.response
  }
}
export let osPlatform = {
  "Win32": "win32",
  "Mac": "darwin",
  "Linux": 'linux',
  "Android": "android",
  "iOS": "ios",
  "Windows": "win32",
  "MacIntel": "darwin",
  "iPhone": "ios",
  "iPad":"ios"
}
let date = new Date()
export let browserFingerPrint = {
  screenWidth: window.screen.availWidth,
  screenHeight: window.screen.availHeight,
  timezoneOffset: date.getTimezoneOffset(),
  deviceMemory: navigator.deviceMemory,
  hardwareConcurrency: navigator.hardwareConcurrency,
  language: navigator.language
}
export const fetchPublicIP = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    if (response.status === 200) {
      return response.data.ip;
    }
  } catch (error) {
    console.error(error);
    return error.response
  }
};

export const passkeySupportCheck = async () => {
  if (window.PublicKeyCredential) {
      try {
          const avail = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
          if (avail) {
              console.log('platform webauthn supported: ', avail);
              return 'REGISTERED';
          } else {
              console.log('webauth supported but not platform webauthn: ', avail);
              return 'NOT_SUPPORTED';
          }
      } catch (err) {
          console.error('Error checking platform webauthn support:', err);
          return 'NOT_SUPPORTED';
      }
  } else {
      console.log('webauthn is not supported');
      return 'NOT_SUPPORTED';
  }
};
export function base64urlToUint8array(base64Bytes) {
  const padding = '===='.substring(0, (4 - (base64Bytes.length % 4)) % 4);
  return base64js.toByteArray((base64Bytes + padding).replace(/\//g, "_").replace(/\+/g, "-"));
}
export function uint8arrayToBase64url(bytes) {
  if (bytes instanceof Uint8Array) {
      return base64js.fromByteArray(bytes).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  } else {
      return uint8arrayToBase64url(new Uint8Array(bytes));
  }
}
export const verifyAccountWithEmailAction = async(token, payload, navigate) => {
  try {
    console.log('running')
      let data = await axios.post(verifyAccountWithEmailUrl(), payload, {
        headers: {
          Authorization: `${token}`
      }
      })
      if(data && data.status === 200){
          return data
      }
  } catch (error) {
      console.log(error)
      if(error.response.status === 403){
          return error.response
      }
      errorHandling(error.response?.status)
      return error.response
  }
}
export const activateAccountWithEmailAction = async(token, payload, navigate) => {
  try {
      let data = await axios.post(activateAccountWithEmailUrl(), payload, {
        headers: {
          Authorization: `${token}`
      }
      })
      if(data && data.status === 200){
          return data
      }
  } catch (error) {
      console.log(error)
      if(error.response.status === 403){
          return error.response
      }
      errorHandling(error.response?.status)
      return error.response
  }
}
export const resendCodeWithEmailAction = async(token) => {
  try {
      let data = await axios.get(resendCodeWithEmailUrl(), {
        headers: {
          Authorization: `${token}`
      }
      })
      if(data && data.status === 200){
          console.log(data)
          return data
      }
  } catch (error) {
      console.log(error)
      return error.response
  }
}