import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import LogoFallback from '../components/LogoFallback'
import passkeyImg from "../assets/ic_fido.svg"
import Footer from '../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { base64urlToUint8array, storePasskeyAction, uint8arrayToBase64url } from '../util/action'
import { useCookies } from 'react-cookie'
const CreatePasskey = () => {
    const location = useLocation()
    const {passkeyData} = location.state;
    const [loading, setLoading] = useState(false)
    const [cookies] = useCookies()
    const errorText = useRef()
    const navigate = useNavigate()
    const [btnText, setBtnText] = useState('Continue')
    const registrePasskey = async() => {
        if(passkeyData){
        try {
        errorText.current.innerHTML =''    
        let passkeyParse = JSON.parse(passkeyData)
        let encodedChallenge = base64urlToUint8array(passkeyParse.publicKey.challenge)
        let userId = base64urlToUint8array(passkeyParse.publicKey.user.id)
        let excludeCredentials= passkeyParse.publicKey.excludeCredentials.map(credential => ({
            ...credential,
            id: base64urlToUint8array(credential.id),
        }))
        let extensions= passkeyParse.publicKey.extensions
        let newPasskeyData = {
            ...passkeyParse.publicKey, challenge: encodedChallenge, user: {...passkeyParse.publicKey.user, id: userId}, excludeCredentials, extensions
        }
        setLoading(true)
        let data = await navigator.credentials.create({publicKey: newPasskeyData})
        if(data){
            console.log(data, ' test: ', data.getClientExtensionResults())
            let obj = {
                    id: data.id,
                    type: data.type,
                    response: {
                        attestationObject: uint8arrayToBase64url(data.response.attestationObject), 
                        clientDataJSON: uint8arrayToBase64url(data.response.clientDataJSON), 
                        transports: (data.response.getTransports && data.response.getTransports()) || [],
                    },
                    clientExtensionResults: data.getClientExtensionResults()
               
            }
            let resp = await storePasskeyAction(cookies.USER_AUTH?.Token, obj, navigate)
            setLoading(false)
            if(resp && resp.status === 200){
                navigate('/dashboard')
            }
        }else {
            setLoading(false)
            setBtnText('Try Again')
        }
    } catch (error) {
        console.error('logging error: ', error);
            setLoading(false)
            setBtnText('Try Again')
            errorText.current.innerHTML = 'Passkey enrollment either timed out or was canceled. Please try again.' 
    }
    }

    }
  return (
    <>
    <Box sx={{
            height:{sm: '85vh',xs: "80vh" },  display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center"
        }}>
            <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '8.8vh', sm: '6.5vh', xs: "10vh" } }}><LogoFallback /></Box>
            <Box sx={{ marginBottom: '16px', background: `#EFEFEF`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: {sm: "40px", xs: "26px"} }}>
                <Box
                component="img"
                sx={{
                    height: { xs: 48, sm: 80 },
                    width: { xs: 48, sm: 80 },
                }}
                alt="passkey"
                src={passkeyImg}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '3vh' }}>
                <Typography sx={{
                    font: { xs: "normal normal bold 16px/20px Open Sans", sm: 'normal normal bold 32px/43px Open Sans' }, color: '#0B3558', marginBottom: '8px'
                }}>
                    {"Setup Passkey"}
                </Typography>
                <Typography sx={{
                    font: { sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/16px Open Sans" }, color: '#0B3558', width: {sm: '406px',xs: '330px'}, textAlign: 'center'
                }}>
                    Please follow the prompts that appear to register your platform authenticator like Touch ID or Windows Hello
                </Typography>
                <Typography sx={{
                    font: { sm: 'normal normal normal 14px/19px Open Sans', xs: "normal normal normal 12px/16px Open Sans" }, color: '#A2A2A2', width: {sm:'400px', xs: "330px"}, marginTop: "16px", marginBottom: "24px", textAlign: 'center'
                }}>
                Note: Zaperon doesn't collect or store biometrics of any user. Biometric only used for identity verification purpose only
                </Typography>
                <Button
                            disabled={false}
                            variant="contained"
                            onClick={() => {
                               registrePasskey()
                            }}
                            sx={{ marginTop: '0px', textTransform: 'capitalize', background: '#003FB9 0% 0% no-repeat padding-box', boxShadow: '0px 3px 6px #00000029', borderRadius: '4px', height: { md: '43px', sm: '43px', xs: "43px" }, width: {sm: '360px', xs: "300px"},marginLeft: "auto", marginRight: "auto", border: 'none' }}>
                            <Typography sx={{ width: 'fit-content', font: { sm: 'normal normal 600 14px/19px Open Sans' }, color: '#FFFFFF', fontWeight: 600 }}>
                                {loading
                                    ?
                                    <CircularProgress style={{ width: '24px', height: '24px', color: '#FFFFFF' }} />
                                    :
                                     btnText
                                }
                            </Typography>
                        </Button>
                <p ref={errorText} style={{ font: 'normal normal 600 14px/22px Open Sans', margin: "0px 0px 0px 0px", color: '#D9292B', textAlign: 'center', padding: '0', width: "360px" }} className='errorPara'></p>

            </Box>
            <Box>
            </Box>
            <Footer /></Box></>
  )
}

export default CreatePasskey