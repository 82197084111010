import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import LogoFallback from '../components/LogoFallback'
import passkeyImg from "../assets/ic_fido.svg"
import Footer from '../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { base64urlToUint8array, getJWKToken, uint8arrayToBase64url, verifyPasskeyAction } from '../util/action'
import { useCookies } from 'react-cookie'
import queryString from 'query-string'
import { BaseUrl, OauthUrl } from '../util/api'
import jwtDecode from 'jwt-decode'
import { checkPlatform } from '../util/IsClientRunning'
import axios from 'axios'
const VerifyPasskey = () => {
    const location = useLocation()
    const { url, SAMLResponse, RelayState, SAMLRequest, username, type, clientId } = queryString.parse(location.search);
    const [cookies, setCookie] = useCookies()
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [btnText, setBtnText] = useState('Continue')
    const {passkeyData} = location.state
    const incorrectError= useRef()
    const responsive = {
        verifyAccountText2: {
            font: { md: 'normal normal normal 16px/22px Open Sans', lg: 'normal normal normal 16px/22px Open Sans', sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/15px Open Sans" },
            letterSpacing: 0,
            color: '#0B3558',
            width: '349px',
            textAlign: 'left',
            marginBottom: { md: '32px', sm: '2.77vh', xs: "4vh" },
        },
    }
    const navigateToSSORedirect = async (email, saml, relay, token) => {
        axios.post(BaseUrl + 'ssologin?username=' + encodeURIComponent(email) + '&SAMLRequest=' + encodeURIComponent(saml) + '&RelayState=' + encodeURIComponent(relay), '_self', {
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            if (res.status === 200) {
                let url = res.data.acsUrl;
                let SAMLResponse = res.data.SAMLResponse;
                let relayState = res.data.RelayState;
                // if (firstTimeUser != "false") {
                //     navigate(`/setupMFA2nd?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`)
                // } else {
                if (checkPlatform() === false) {
                    setCookie('RELAY', relayState, { expires: new Date(Date.now() + 86400000) })
                    setCookie('SAML', SAMLResponse, { expires: new Date(Date.now() + 86400000) })
                    localStorage.setItem('saml', SAMLResponse)
                    localStorage.setItem('relay', relayState)
                }

                navigate(`/Ssoserviceredirect?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(relayState)}`)
                // }
            }
            else if (res.status === 401 || res.status === 400) {
                if (res.data === 'UNVERIFIED') {
                    navigate("/login")
                }
                else if (res.data === 'QR_COMPLETE') {
                    navigate("/setupMFA?step=3")
                }
                else if (res.data === 'VERIFICATION_COMPLETE') {
                    navigate("/setupMFA")

                }

                else {
                    navigate("/login")
                }


                // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
            }
            else if (res.status === 403 || res.status === 405) {
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
        }).catch((error) => {
            // 401 status means user hasn't verified with the otp
            if (error.response.status === 403 || error.response.status === 405) {
                navigate('/unauthorised', {
                    state: {
                        sso: true
                    }
                })
                return;
            }
            else if (error.response.data.error === 'Unauthorized') {
                navigate("/sessionTimeout")
            }
            else if (error.response.data === 'UNVERIFIED') {
                navigate("/setupMFA?step=1")
            }
            else if (error.response.data === 'QR_COMPLETE') {
                navigate("/setupMFA?step=3")
            }
            else if (error.response.data === 'VERIFICATION_COMPLETE') {
                navigate(`/setupMFA2nd?username=${encodeURIComponent(username)}&SAMLRequest=${encodeURIComponent(SAMLRequest)}&RelayState=${encodeURIComponent(RelayState)}`)
            } else if (error.response?.status === 409) {
                navigate("/accessBlock", { state: { ...error.response.data, email } });
            } else {
                navigate("/login")
            }
            incorrectError.current.innerHTML = "Something went wrong! Please contact your admin"

            // navigate(`/setupMFA2nd?SAMLRequest=${encodeURIComponent(saml)}&RelayState=${encodeURIComponent(relay)}&email=${encodeURIComponent(email)}`)
        })
    }
    const jwkTokenData = async (token) => {
        if(!cookies.USER_OAUTH?.Token){
            let data = await getJWKToken(token, clientId)
            if(data && data.status === 200){
                let jwk = jwtDecode(data.data?.access_token)
                setCookie('USER_OAUTH', {Token: data.data?.access_token}, {expires: new Date(jwk.exp * 1000)})
                window.location.href = `${OauthUrl}login?access_token=${data.data?.access_token}`;
                return data
            }else if(data && data.status === 403){
                return navigate('/unauthorised', {state: {back: true}})
            }
        }else {
            window.location.href = `${OauthUrl}login?access_token=${cookies.USER_OAUTH?.Token}`;
        }
        
    } 
    const verifyPasskey = async() => {
         
        if(passkeyData){
        try {
            
        incorrectError.current.innerHTML = ''
            
        let passkeyParse = JSON.parse(passkeyData)
        let encodedChallenge = base64urlToUint8array(passkeyParse.publicKey.challenge)
        let newPasskeyData = {
            ...passkeyParse.publicKey,
            allowCredentials: passkeyParse.publicKey.allowCredentials
            && passkeyParse.publicKey.allowCredentials.map(credential => ({
            ...credential,
            id: base64urlToUint8array(credential.id),
            })),
            challenge: encodedChallenge,
            extensions: passkeyParse.publicKey.extensions,

        }
        setLoad(true)
        let data = await navigator.credentials.get({publicKey: newPasskeyData})
        if(data){
            console.log(data)
            let obj = {
                    id: data.id,
                    type: data.type,
                    response: {
                        authenticatorData: uint8arrayToBase64url(data.response.authenticatorData),
                        clientDataJSON: uint8arrayToBase64url(data.response.clientDataJSON),
                        signature: uint8arrayToBase64url(data.response.signature),
                        userHandle: data.response.userHandle && uint8arrayToBase64url(data.response.userHandle),
                        },
                    clientExtensionResults: data.getClientExtensionResults(),
            }
            let resp = await verifyPasskeyAction(cookies.USER_AUTH?.Token, obj, navigate)
            setLoad(false)
            if(resp && resp.status === 200){
                if (SAMLRequest && RelayState && username) {
                    return navigateToSSORedirect(username, SAMLRequest, RelayState, cookies.USER_AUTH?.Token)
                }else if (type === 'oauth2'){
                    return jwkTokenData(cookies.USER_AUTH?.Token)
                }
                else if (url && SAMLResponse && RelayState) {
                    return window.location.assign(`/Ssoserviceredirect?url=${encodeURIComponent(url)}&SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${encodeURIComponent(RelayState)}`);
                } else if (checkPlatform() === 'Mobile') {
                    return navigate('/dashboard')
                }else {
                    let homeUrl = localStorage.getItem("zap_panel")
                    if (homeUrl && homeUrl !== 'dashboard') {
                        window.location.href = `/${homeUrl}`
                    }
                    else {
                        navigate('/dashboard')
                    }
                }
            }
        }else {
            setLoad(false)
            setBtnText('Try Again')
        }
    } catch (error) {
        console.error('logging error: ', error);
        setBtnText('Try Again')
        setLoad(false)
        incorrectError.current.innerHTML = 'Passkey enrollment either timed out or was canceled. Please try again.'
                
    }
    }
    }
    useEffect(() => {
        setLoad(true)
        verifyPasskey()
        // eslint-disable-next-line
    },[])
  return (
    <>
    <Box sx={{
            height:{sm: '85vh',xs: "80vh" },  display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: "center"
        }}>
            <Box sx={{ width: '180px', height: '90px', marginBottom: { md: '8.8vh', sm: '6.5vh', xs: "10vh" } }}><LogoFallback /></Box>
            <Box sx={{ marginBottom: '16px', background: `#EFEFEF`, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: {sm: "40px", xs: "26px"} }}>
                <Box
                component="img"
                sx={{
                    height: { xs: 48, sm: 80 },
                    width: { xs: 48, sm: 80 },
                }}
                alt="passkey"
                src={passkeyImg}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '3vh' }}>
                <Typography sx={{
                    font: { xs: "normal normal bold 16px/20px Open Sans", sm: 'normal normal bold 32px/43px Open Sans' }, color: '#0B3558', marginBottom: '8px'
                }}>
                    {"Verify Passkey"}
                </Typography>
                <Typography sx={{
                    font: { sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/16px Open Sans" }, color: '#0B3558', width: {sm: '406px',xs: '330px'}, textAlign: 'center'
                }}>
                Please use your passkey to verify your identity.
                </Typography>
                <Button
                            disabled={false}
                            variant="contained"
                            onClick={() => {
                                if(load === false){
                                verifyPasskey()
                                }
                            }}
                            sx={{ marginTop: '1.85vh', textTransform: 'capitalize', background: '#003FB9 0% 0% no-repeat padding-box', boxShadow: '0px 3px 6px #00000029', borderRadius: '4px', height: { md: '43px', sm: '43px', xs: "43px" }, width: {sm: '360px', xs: "300px"},marginLeft: "auto", marginRight: "auto", border: 'none' }}>
                            <Typography sx={{ width: 'fit-content', font: { sm: 'normal normal 600 14px/19px Open Sans' }, color: '#FFFFFF', fontWeight: 600 }}>
                                {load
                                    ?
                                    <CircularProgress style={{ width: '24px', height: '24px', color: '#FFFFFF' }} />
                                    :
                                     btnText
                                }
                            </Typography>
                        </Button>
                        <p style={{ ...responsive.verifyAccountText2, marginTop: '1.48vh', marginBottom: '8px', color: '#D9292B', font: 'normal normal normal 14px/22px Open Sans' }} className="erroPara" ref={incorrectError}></p>
            </Box>
            <Box>
            </Box>
            <Footer /></Box></>
  )
}

export default VerifyPasskey