import { TextField } from "@mui/material";
import { styled } from "@mui/styles";

const InputTextField = styled(TextField)({

    '& input:valid:focus + fieldset': {
        borderColor: '#003FB9',
    },
});

export default InputTextField;
