import { Box, Typography } from '@mui/material'
import React, { useContext, useRef } from 'react';
import SetupMFAListItem from '../components/SetupMFAListItem'
import verifyAccountImg from "../assets/ic_verift_account.svg"
import Footer from "../components/Footer"
import verifyICON from '../assets/ic_verify_app_icon@2x.png'
import QRCode from "react-qr-code";
import './SetupMFA.css'
import axios from 'axios';
import { useCookies } from 'react-cookie';
import $ from 'jquery';
import LocationPopup from '../util/LocationPopup';
import Redirect from './Redirect';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkPlatform } from '../util/IsClientRunning';
import { errorHandling, passkeySupportCheck } from '../util/action';
import LogoFallback from '../components/LogoFallback';
import { AppContext } from '../App';


const BaseUrl = process.env.REACT_APP_BASE_URL

const responsive = {
    totalMarginLeft: {
        // paddingLeft: '5%',
    },
    logoMargins: {
        display: 'flex',
        width: '180px',
        justifyContent: 'center',
        marginTop: '20px',
        marginBottom: { md: '4vh', sm: '2vh' },
        height: '90px'
    },
    setupMFAtext: {
        fontWeight: 700,
        font: {sm: 'normal normal bold 32px/43px Open Sans' },
        color: '#0B3558',
        marginBottom: '8px'
    },
    textUnderSetupMFA: {
        font: { sm: 'normal normal normal 16px/22px Open Sans' },
        color: { md: '#0B3558' },
        marginBottom: { md: '7.4vh', sm: '5.92vh' }
    },
    setupMFAListItem: {
        box: {
            marginBottom: { md: '24px', lg: '16px', sm: '12px' }
        },
        icon: {
            borderRadius: '50%',
            width: '48px',
            height: '48px',
            font: { sm: 'normal normal bold 22px/30px Open Sans' },
        },
        text: {
            width: 'fit-content',
            marginLeft: '16px',
            font: {sm: 'normal normal bold 16px/22px Open Sans' },
        }
    },
    verifyScreenShot: {
        backgroundColor: '#EFEFEF',
        marginBottom: { md: '32px', lg: '2.77vh' },
        width: { md: '300px', sm: '250px' },
        height: { md: '300px', sm: '250px' },
    },
    textInsideVerifyScreenShot: {
        color: '#A2A2A2',
        font: 'italic normal normal 20px/27px Open Sans',
    },
    firstInstruction: {
        font: { lg: 'normal normal normal 20px/27px Open Sans', sm: 'normal normal normal 20px/27px Open Sans' },
        color: '#0B3558',
        width: '530px',
        letterSpacing: '0px',
        marginBottom: '2.22vh'
    },
    firstButton: {
        width: { lg: '500px', sm: '300px' },
        height: '61px',
        background: '#003FB9 0% 0% no-repeat padding-box',
        borderRadius: '4px'
    },
    textInsideButton: {
        font: { lg: 'normal normal 600 24px/33px Open Sans', sm: 'normal normal 600 20px/25px Open Sans' },
        color: '#FFFFFF'
    },
    secondPageBox: {
        height: 'auto'
    },
    QRcode: {
        width: '250px' ,
        height: '250px' ,
    },
    theORtext: {
        font: 'normal normal bold 20px/27px Open Sans',
        color: '#A2A2A2',
        margin: '24px'
    },
    insideQRCodeRightHalf: {
        width: { md: '16.56vw', sm: '14.1vw' },
        minWidth: { md: '216px', sm: '216px' },
        height: { lg: '135px' },
    },
    insideQRCodeRightHalfSubpartOne: {
        width: '216px',
        height: { sm: '80px' },
        marginBottom: '5%'
    },
    insideQRCodeRightHalfSubpartOneText: {
        font: { sm: 'normal normal bold 32px/38px Open Sans' },
        color: '#0B3558',
        letterSpacing: '0'
    },
    insideQRCodeRightHalfSubpartTwo: {
        font: { sm: 'italic normal normal 12px/17px Open Sans' },
        color: '#A2A2A2',
        letterSpacing: 0,
        textAlign:'center',
        width: "216px"
    },
    scanQRcodeText: {
        // width: { lg: '326px' },
        height: { lg: '65px' },
        font: { lg: 'normal normal bold 48px/65px Open Sans', sm: 'normal normal bold 36px/65px Open Sans' },
        color: '#0B3558',
        marginBottom: '2%',
        marginTop: '4%'
    },
    scanQRcodeText1: {
        font: { sm: 'normal normal normal 14px/19px Open Sans' },
        color: '#0B3558',
        marginBottom: '4%'
    },
    scanQRcodeText2: {
        font: { lg: 'normal normal 600 18px/24px Open Sans' },
        color: '#003FB9'
    },
    verifyAccount: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    verifyAccountImg: {
        width: '50%',
        height: '50%'
    },
    verifyAccountImgBox: {
        width:'200px',
        height:'200px',
        borderRadius: '50%',
        backgroundColor: '#EFEFEF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '3%',
    },
    verifyAccountText1: {
        font: { md: 'normal normal bold 32px/43px Open Sans', lg: 'normal normal bold 32px/43px Open Sans', sm: 'normal normal bold 32px/43px Open Sans', xs: "normal normal bold 16px/25px Open Sans" },
        color: '#0B3558',
        marginBottom: '8px'
    },
    verifyAccountText2: {
        font: { md: 'normal normal normal 16px/22px Open Sans', lg: 'normal normal normal 16px/22px Open Sans', sm: 'normal normal normal 16px/22px Open Sans', xs: "normal normal normal 12px/15px Open Sans" },
        letterSpacing: 0,
        color: '#0B3558',
        width: '350px',
        height: "43px",
        textAlign: 'center',
        marginBottom: { md: '32px', sm: '2.77vh' },
    },
    verifyAccountText3: {
        font: { md: 'normal normal bold 112px/112px Open Sans', sm: 'normal normal bold 80px/109px Open Sans' },
        letterSpacing: { md: '22.4px', sm: '16px' },
        color: '#003FB9',
    },
    entercode: {
        width: "48px",
        height: "48px",
        textAlign: "center",
        margin: '0 6px 2.77vh 6px', 
        padding: 0,
        border: "2px solid #003FB9",
        borderRadius: "4px",
        font: "normal normal 600 24px/33px Open Sans",
        color: "#0B3558",
    }
}
let countOfWrongCode = 0;
let scanCode2;
let userLocation = { lat: '', long: '', accuracy: "" }
let locationId;
function SetupMFA() {
    const [searchParams, setSearchParams] = useSearchParams();
    const {sendObj} = useContext(AppContext)
    const {userMfa, passkeyStatus} = sendObj;
    let step = searchParams.get('step') || 1
    const [cookies, setCookie] = useCookies("");
    const [current, setCurrent] = React.useState(+step);
    const [scanCode, setScanCode] = React.useState(scanCode2);
    const [showPopup, setShowPopup] = React.useState(false);
    const qrScreenError = useRef()
    const verifyScreenError = useRef()
    const navigate = useNavigate()
    if (current === 3 && !scanCode) {
        scanCode2 = 'dummy'
        setScanCode("dummy")
    }
    React.useEffect(() => {
        if (current === 2 && !scanCode2) {
            fetchQr()
        }else if(current === 3){
            window.scrollTo(0, document.body.scrollHeight);
        }
        // eslint-disable-next-line
    }, [])
    const logout = () => {
        let token = cookies.USER_AUTH.Token
        let date = new Date();
        setCookie('USER_AUTH', {}, { expires: date });
        setCookie('USER_EMAIL', {}, { expires: date });
        setCookie('FIRST', {}, { expires: date });
        axios.get(BaseUrl + 'logout/user-dashboard', {
            headers: {
                "Authorization": token
            }
        })
            .then(() => {
                navigate('/login')
            })
            .catch(() => {
                navigate('/login')
            })
    }
    function fetchQr() {
        let token = cookies.USER_AUTH?.Token
        axios({
            method: 'get',
            url: BaseUrl + 'zaperon/getQRcode',
            headers: {
                "Authorization": token
            },
        }).then((res) => {
            console.log(res)
            if (res.status === 200) {
                scanCode2 = res.data
                setScanCode(scanCode2)
            }
            else if (res.status === 401) {
                navigate('/login')
            }
            else {
                navigate('/login')
            }
        }).catch(function (error) {
            console.log(error)
            navigate('/login')
        });
    }

    if (!scanCode || scanCode === '') {
        if (current === 1) {
            fetchQr()
        }
    }

    function qrScannedHandler() {
        let token = cookies.USER_AUTH?.Token
        axios({
            method: 'get',
            url: BaseUrl + 'zaperon/getUserStatus',
            headers: {
                "Authorization": token
            }
        }).then((res) => {
            console.log(res)
            if (res.status === 200 && res.data.verifyStatus === 'QR_COMPLETE') {
                setSearchParams({ step: 3 })
                setCurrent(3)
            }
            else if (res.status === 401) {
                navigate('/login')
            }
            else {
                qrScreenError.current.innerHTML = "Please complete QR scan on your<br/>phone before proceeding"
            }

        }).catch(function (error) {
            console.log(qrScreenError)
            qrScreenError.current.innerHTML = "Please complete QR scan on your<br/>phone before proceeding"
            navigate('/login')
        });
    }
    // const getGeolocation = async(lat, long) => {
    //     try {
           
    //         let latitude = lat 
    //         let longitude = long
    //     let data = await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
    //         console.log(data, ' geolocatoin data ', longitude)
    //         if(data && data.status === 200){
    //             return data.data;
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
   async function verifyScreenContinueHandler(userEnteredCode) {
        let token = cookies.USER_AUTH?.Token
        let gpsEnabled = userLocation.accuracy < 100 ? "yes" : "no";
        var raw;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");
        // let {city, countryName, principalSubdivision} = await getGeolocation(userLocation.lat, userLocation.long)
        if(userLocation && userLocation.lat !== "" && userLocation.long !== ""){
             raw = {
                "totp": userEnteredCode,
                "location": userLocation.lat + "," + userLocation.long,
                "gpsEnabled": gpsEnabled,
                "verifyStatus": "VERIFICATION_COMPLETE",
                // city: city,
                // state: principalSubdivision,
                // country: countryName,
            };
        }else {
            raw = {
                "totp": userEnteredCode,
                "location": "0,0",
                "gpsEnabled": 'no',
                "verifyStatus": "VERIFICATION_COMPLETE",
            };
        }
      
        if(userMfa === 'PASSKEY'){
            raw.passkey_status  = await passkeySupportCheck()
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: 'follow'
        };
        
        fetch(BaseUrl + 'zaperon/completeRegMatchLocation', requestOptions)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    navigator.geolocation.clearWatch(locationId);
                    var now = new Date();
                    let data = res.json()
                    console.log(data);
                    now.setTime(now.getTime() + 1 * 1800 * 1000);
                    setCookie('VERIFIED', "true", { expires: now });
                    if(userMfa === 'ZAPERON_VERIFY'){
                        navigate('/dashboard')
                    }else if(userMfa === 'PASSKEY'){
                        if( raw.passkey_status === 'NOT_SUPPORTED'){
                            navigate('/dashboard')
                        }else if(passkeyStatus === 'NOT_REGISTERED') {
                            navigate('/createPasskey', {state: {...sendObj}})
                        }else {
                            navigate('/verifyPasskey', {state: {...sendObj}})
                        }
                    }else {
                        navigate('/dashboard')
                    }
                }
                else if (res.status === 401 || res.status === 409 || res.status === 403) {
                    let err = errorHandling(res.status)
                    if(err === 409){
                        navigate("/accessBlock", { state: { ...res.data, email: cookies.USER_EMAIL?.email } });
                    }
                }
                else if (res.status === 400 || res.status === 500) {
                    throw res;
                }
                else {
                    if (countOfWrongCode > 2) {
                        logout()
                    }
                    countOfWrongCode = countOfWrongCode + 1
                    verifyScreenError.current.innerHTML = "Incorrect code. Please try again"
                }
            })
            .catch(function (error) {
                console.log(error)
                navigate('/login')
                if (countOfWrongCode > 2) {
                    logout()
                }
                countOfWrongCode = countOfWrongCode + 1
                if(error?.status === 400){
                    error.text().then((re) => {
                        if(re === 'Incorrect OTP'){
                        verifyScreenError.current.innerHTML = "Incorrect code, Please try again"
                        }else {
                        verifyScreenError.current.innerHTML = "Your mobile device is not in proximity. Please move it closer and try again."
                        }
                    }).catch(err => console.log(err))
                }else if(error?.status === 500){
                    error.json().then((re) => {
                        if(re?.message === 'Location Failed'){
                            verifyScreenError.current.innerHTML = "We are unable to get your location. Please try again."
                        }else {
                            verifyScreenError.current.innerHTML = "Something went wrong! Please contact your admin"
                        }
                    }).catch(err => console.log(err))
                }
                else if(error.response?.status === 401 || error.response?.status === 409 || error.response?.status === 403){
                    let err = errorHandling(error.response?.status)
                    if(err === 409){
                     navigate("/accessBlock", { state: { ...error.response?.data, email: cookies.USER_EMAIL?.email } });
                    }
                }else {
                    verifyScreenError.current.innerHTML = "Something went wrong! Please contact your admin"
                }
            });





        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", token);
        // myHeaders.append("Content-Type", "application/json");
        // console.log(JSON.stringify({
        //     "totp": userEnteredCode,
        //     "location": userLocation.lat + "," + userLocation.long,
        //     "gpsEnabled": gpsEnabled,
        //     "verifyStatus": 'VERIFICATION_COMPLETE'
        // }))
        // axios({
        //     method: 'post',
        //     url: BaseUrl + 'zaperon/completeRegMatchLocation',
        //     headers: myHeaders,
        //     body: JSON.stringify({
        //         totp: userEnteredCode,
        //         location: userLocation.lat + "," + userLocation.long,
        //         gpsEnabled: gpsEnabled,
        //         verifyStatus: 'VERIFICATION_COMPLETE'
        //     })
        // })
        // .then((res) => {
        //     console.log(res)
        //     if (res.status === 200) {
        //         navigator.geolocation.clearWatch(locationId);
        //         navigate('/dashboard')
        //     }
        //     else if (res.status === 401) {
        //         navigate('/login')
        //     }
        //     else if (res.status === 400) {
        //         if (res.body == "locaion Mismatch") {
        //             verifyScreenError.current.innerHTML = "Please move your phone closer to your device and try again"
        //         }
        //         else {
        //             verifyScreenError.current.innerHTML = "Incorrect code. Please try again"
        //         }
        //     }
        //     else {
        //         verifyScreenError.current.innerHTML = "Incorrect code. Please try again"
        //     }

        // })
        // .catch(function (error) {
        //     console.log(error)
        //     // navigate('/login')
        //     verifyScreenError.current.innerHTML = "Incorrect code. Please try again"
        // });
    }

    const locationSuccessCallback = (position) => {
        if (!userLocation.accuracy || userLocation.accuracy === '' || userLocation.accuracy > position.coords.accuracy) {
            userLocation = { lat: position.coords.latitude, long: position.coords.longitude, accuracy: position.coords.accuracy }
        }
        setShowPopup(false)
    };

   const locationErrorCallback = (error) => {
        if (checkPlatform() === 'Mobile') {
            return setShowPopup(false)
        }
        if(error?.code === 1){
            setShowPopup(true)
        }
        console.log(error, error?.message);
    };
    const fetchLocation = () => {
        setShowPopup(false)
        locationId = navigator.geolocation.watchPosition(locationSuccessCallback, locationErrorCallback);
    };


    React.useEffect(() => {
        let token = cookies.USER_AUTH?.Token
        if (!token) {
            navigate("/login")
        }
        if (current === 3) {
            // to get location
            if(checkPlatform() === true){
                navigator.permissions && navigator.permissions.query({ name: 'geolocation' })
                .then(function (PermissionStatus) {
                    if (PermissionStatus.state === 'granted') {
                        //allowed
                        fetchLocation()
                    } else if (PermissionStatus.state === 'prompt') {
                        // prompt - not yet granted or denied
                        setShowPopup(true)
                    } else {
                        //denied
                        setShowPopup(true)
                    }
                })
            }
            else if(checkPlatform() !== true){
                fetchLocation()
            }

            //for 6digit inputs
            $('.code-group').find('input').each(function () {
                $(this).attr('maxlength', 1);
                $(this).on('keyup', function (e) {
                    var parent = $($(this).parent());
                    if (e.keyCode === 8 || e.keyCode === 37) {
                        var prev = parent.find('input#' + $(this).data('previous'));

                        if (prev.length) {
                            $(prev).select();
                        }
                    } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                        var next = parent.find('input#' + $(this).data('next'));

                        if (next.length) {
                            $(next).select();
                        } else {
                            if (parent.data('autosubmit')) {
                                parent.submit();
                            }
                        }
                    }
                });
            });
        }
        // eslint-disable-next-line
    }, [current])


    return (<>
        {scanCode && scanCode !== '' ?
            <>
                {showPopup &&
                    <LocationPopup clickHandler={fetchLocation} />}
                <Box sx={{
                    ...responsive.totalMarginLeft, minHeight: '80vh', width: '100vw'
                }}>
                    <Box sx={{ ...responsive.logoMargins, marginLeft: "auto", marginRight: "auto" }}><LogoFallback /></Box>
                    {/* Main */}
                    <Box sx={{ display: 'flex', justifyContent: {sm: 'space-between',xs: "center"}, paddingLeft: { lg: '140px', sm: '120px' }, paddingRight: { lg: '140px', sm: '120px' }, paddingTop: {lg: "70px", sm: "-20px"} }}>
                        <Box sx={{ width: current === 2 ? "45%" : "55%", float: 'left', display: {sm: "initial", xs: 'none'} }}>

                            <Typography sx={{ ...responsive.setupMFAtext }}>
                                Multi-Factor Authentication
                            </Typography>
                            <Typography sx={{ ...responsive.textUnderSetupMFA }}>
                                Enable advanced security on your account
                            </Typography>
                            <SetupMFAListItem styles={responsive.setupMFAListItem} index={1} current={current === 1} text="Download Zaperon Verify App" checked={current > 1} />
                            <SetupMFAListItem styles={responsive.setupMFAListItem} index={2} current={current === 2} text="Scan & Add Account" checked={current > 2} />
                            <SetupMFAListItem styles={responsive.setupMFAListItem} index={3} current={current === 3} text="Setup & Verify" />

                        </Box>
                        {current === 1 &&
                            <Box sx={{ width: '45%', float: 'right' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '530px' }}>
                                    <Box sx={{ ...responsive.verifyScreenShot, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                        <img src={verifyICON} alt="" width={'100%'} height={'100%'} style={{ backgroundColor: 'white' }} />
                                    </Box>

                                    <Typography sx={{ ...responsive.firstInstruction, textAlign: 'center', font: "normal normal normal 14px/19px Open Sans" }}>
                                        First Download the <span style={{ color: '#003FB9', fontWeight: 700 }}>Zaperon Verify App</span> from the<br /> <strong>App Store</strong> or <strong>Google Play Store</strong> & follow the instructions.
                                    </Typography>
                                    {/* <Button sx={{ ...responsive.firstButton }} onClick={() => { setCurrent(2) }}>
                                <Typography sx={{ ...responsive.textInsideButton, textTransform: 'capitalize' }}>Next</Typography>
                            </Button> */}
                                    <button type="submit" className="nxtBtn" onClick={() => {
                                        setSearchParams({ step: 2 })
                                        setCurrent(2)
                                    }}
                                        id="signin">Next</button>
                                </div>
                            </Box>
                        }
                        {current === 2 &&
                            <Box sx={{ width: '60%', display: 'flex', alignItems: 'end', flexDirection: 'column', float: 'right' }}>
                                <div>
                                    <Box sx={{ ...responsive.secondPageBox, alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                                        <Box sx={{ ...responsive.QRcode, border: ' 4px solid #EFEFEF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <QRCode
                                                style={{ width: "180px", height: "180px", margin: 'auto' }}
                                                value={scanCode}
                                            />
                                        </Box>
                                        <Typography sx={{ ...responsive.theORtext }}>OR</Typography>
                                        <Box sx={{ ...responsive.insideQRCodeRightHalf }}>
                                            <Box sx={{ ...responsive.insideQRCodeRightHalfSubpartOne, backgroundColor: '#EFEFEF' }}>
                                                <Typography sx={{ ...responsive.insideQRCodeRightHalfSubpartOneText, display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>{scanCode}</Typography>
                                            </Box>
                                            <Typography sx={{ ...responsive.insideQRCodeRightHalfSubpartTwo }}>
                                                *If you have problem scanning, you can enter above code manually.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        {/* <Typography sx={{ ...responsive.scanQRcodeText }}>
                                            Scan QR Code
                                        </Typography> */}
                                        <Typography sx={{ ...responsive.scanQRcodeText1, textAlign: 'center', marginTop: '32px' }}>
                                            To add your account in <span style={{ fontWeight: 700 }}>Zaperon Verify App</span>,<br />scan QR code above and press Next button
                                        </Typography>
                                        <button type="submit" className="nxtBtn" style={{ width: '400px' }} onClick={() => {
                                            qrScreenError.current.innerHTML = "";
                                            qrScannedHandler()
                                        }}>Next</button>
                                        <p style={{ ...responsive.verifyAccountText2, marginTop: '1.48vh', marginBottom: '8px', marginRight: "auto", marginLeft: "auto", color: '#D9292B'}} ref={qrScreenError}></p>
                                    </div>
                                </div>
                                {/* <div style={{ width: '100%', textAlign: 'center' }}>
                            <Typography sx={{ ...responsive.scanQRcodeText }}>
                                Scan QR Code
                            </Typography>
                            <Typography sx={{ ...responsive.scanQRcodeText1, textAlign: 'center' }}>
                                To add your account in <span style={{ fontWeight: 600 }}>Zaperon Verify App</span>,<br />scan QR code above and press Next button
                            </Typography>
                        </div> */}
                            </Box>
                        }
                        {current === 3 &&
                            <div style={{ width: '55%', display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ ...responsive.verifyAccount, float: 'right' }}>
                                    <Box sx={{ ...responsive.verifyAccountImgBox }}>
                                        <img src={verifyAccountImg} style={{ ...responsive.verifyAccountImg }} alt="" />
                                    </Box>
                                    <Typography sx={{ ...responsive.verifyAccountText1, textAlign: 'center' }}>Verify Your Account</Typography>
                                    <Typography sx={{ ...responsive.verifyAccountText2 }}>Please complete the setup on <span style={{ fontWeight: 600 }}>Zaperon Verify App</span> & Enter the 6-digit code to continue</Typography>

                                    {/* <Typography sx={{ ...responsive.verifyAccountText3 }}>{otp}</Typography> */}
                                    <div>
                                        <form
                                            className="code-group"
                                            data-group-name="digits"
                                            data-autosubmit="true"
                                            autoComplete="off"
                                            id='6digit'
                                            style={{display: 'flex', flexDirection: "column", width: "fit-content", justifyContent: 'center', alignItems: "center"}}
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                            }}
                                        >
                                            <div style={{ display: 'flex', justifyContent: "center" }}>
                                                <input type="text" id="digit-1" name="digit-1" data-next="digit-2" style={{ ...responsive.entercode }} />
                                                <input
                                                    type="text"
                                                    id="digit-2"
                                                    name="digit-2"
                                                    data-next="digit-3"
                                                    data-previous="digit-1"
                                                    style={{ ...responsive.entercode }}
                                                />
                                                <input
                                                    type="text"
                                                    id="digit-3"
                                                    name="digit-3"
                                                    data-next="digit-4"
                                                    data-previous="digit-2"
                                                    style={{ ...responsive.entercode }}
                                                />
                                                <input
                                                    type="text"
                                                    id="digit-4"
                                                    name="digit-4"
                                                    data-next="digit-5"
                                                    data-previous="digit-3"
                                                    style={{ ...responsive.entercode }}
                                                />
                                                <input
                                                    type="text"
                                                    id="digit-5"
                                                    name="digit-5"
                                                    data-next="digit-6"
                                                    data-previous="digit-4"
                                                    style={{ ...responsive.entercode }}
                                                />
                                                <input type="text" id="digit-6" name="digit-6" data-previous="digit-5" style={{ ...responsive.entercode }} />
                                            </div>
                                            <button type="submit" className="nxtBtn" style={{}} onClick={() => {
                                                let form = document.getElementById('6digit').getElementsByTagName("input")
                                                let userInput6DigitCode = '';
                                                for (let i = 0; i <= 5; i++) {
                                                    userInput6DigitCode += form[i].value
                                                }
                                                userInput6DigitCode = userInput6DigitCode.trim()
                                                userInput6DigitCode = userInput6DigitCode.replaceAll(' ', '')
                                                if (userInput6DigitCode.length !== 6) {
                                                    verifyScreenError.current.innerHTML = "Please Enter Valid Code"
                                                } else {
                                                    verifyScreenError.current.innerHTML = ""
                                                    verifyScreenContinueHandler(userInput6DigitCode);
                                                }
                                            }}>
                                                Continue
                                            </button>
                                            <p style={{ ...responsive.verifyAccountText2, marginTop: '8px', marginBottom: '8px', color: '#D9292B', font: 'normal normal normal 20px/27px Open Sans' }} ref={verifyScreenError}></p>
                                        </form>
                                    </div>


                                    {/* {timeRunning ?
                                <>
                                    <Typography sx={{ ...responsive.verifyAccountText2, marginTop: { md: '16px', sm: 0 }, marginBottom: '8px' }}>Code expires in</Typography>
                                    <p style={{ ...responsive.verifyAccountText2, marginTop: 0, font: 'normal normal bold 20px/27px Open Sans' }}><span ref={timing}>{pad(parseInt(initialTime / 60)) + ':' + pad(initialTime % 60)}</span> mins</p>
                                </>
                                :
                                <>
                                    <Typography sx={{ ...responsive.verifyAccountText2, marginTop: { md: '16px', sm: 0 }, marginBottom: '8px', color: '#D9292B', font: 'normal normal normal 20px/27px Open Sans' }}>Your code has Expired</Typography>
                                    <Typography sx={{ ...responsive.verifyAccountText2, color: '#003FB9', font: 'normal normal 600 18px/24px Open Sans' }} onClick={requestNewCode}>Request New Code?</Typography>
                                </>} */}
                                </Box>
                            </div>
                        }
                    </Box>
                </Box>
                <Footer />
            </>
            :
            <Redirect />
        }
    </>
    )
}

export default SetupMFA
