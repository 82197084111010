import axios from "axios";
import { checkCookie } from "./action";


const clientServerAddress = 'https://localhost:21214/';
const BaseUrl = process.env.REACT_APP_BASE_URL;

function getClientScript() {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        let head = document.getElementsByTagName('head')[0];
        script.type = 'text/javascript';
        script.async = 0;
        script.src = clientServerAddress;
        script.onload = script.onreadystatechange = function (_, isAbort) {
            resolve({ isAbort: isAbort, script: script })
        };
        script.onerror = function () {
            reject(new Error(`Script load error`))
        };
        head.appendChild(script)
    })
}
export const checkPlatform = () => {
    let platformData = navigator.userAgent;
    if(/iPhone|iPad|iPod|Android|android/i.test(platformData)){
        return "Mobile";
    }
    else if(/WebView/i.test(platformData)){
        return false;
    }
    else {
        return true;
    }
}
function IsClientRunning(navigate, callback,token) {
    if(checkPlatform() === true && checkCookie() === false){
        let client = getClientScript()
        client.then((Clientdata) => {
            let isAbort = Clientdata.isAbort
            let script = Clientdata.script
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = script.onreadystatechange = null;
                script = undefined;
                if (!isAbort) {
                    if (window.isRunning) {
                        window.isRunning = false
                        //calling the callback function if client app is running
                        if (callback) callback()
                    }
                    else {  
                   
                        axios.get(BaseUrl + 'logout/user-dashboard',{
                            headers : {
                                "Authorization" : token
                            }
                        })
                            .then(() => {
                                navigate('/disconnected')
                            })
                            .catch(() => {
                                navigate('/disconnected')
                            })
                    }
                }
            }
        }).catch((e) => {
         
            axios.get(BaseUrl + 'logout/user-dashboard',{
                headers : {
                    "Authorization" : token
                }
            })
                .then((res) => {
                    console.log(res)
                    navigate('/disconnected')
                })
                .catch(() => {
                    navigate('/disconnected')
                })
        })
    }else {
        if(callback) callback()
    }
    
}
export default IsClientRunning
